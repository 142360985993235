import { useCallback, useContext } from 'react';
import { get } from 'lodash-es';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import { DirectQuantityBox } from 'Components/QuantityBox';

import { IInActiveCatItemMobileProps } from './interfaces';

import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

const InActiveCartItemMobile = ({ cartItem }: IInActiveCatItemMobileProps) => {
	const localizationContext = useContext(LocalizationContext);
	const {
		activeCurrency: { currency },
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
	} = localizationContext;
	const t = localizationContext.useFormatMessage();
	const basketContext = useContext(BasketContext);
	const handleOnChangeItemQuantity = get(
		basketContext,
		'handleOnChangeItemQuantity',
		() => {}
	);

	const bundleId = cartItem?.bundleId ?? '';
	const bundleData = cartItem?.bundle ?? null;
	const supplierName = bundleData?.supplier?.nameWeb ?? '';
	const bundleQuantity = cartItem?.quantity ?? 1;

	const getLocalizedValue = useLocalizedValue();

	const basePrice = cartItem?.itemPrice;

	const discountedPrice = cartItem?.itemDiscountPrice;

	const { discountedPriceWithoutVat, discountedPriceWithVat, isDiscounted } =
		getDiscountPriceValues({
			quantityInBasket: bundleQuantity,
			basePrice,
			discountedPrice,
		});

	const priceWithVat = discountedPriceWithVat ?? basePrice?.valueWithVat;

	const priceWithoutVat = discountedPriceWithoutVat ?? basePrice?.value;

	const totalPriceWithVAT =
		convertToActiveCurrencyIfPriceCurrencyIsDifferent({
			price: priceWithVat ?? 0,
			activeCurrency: currency,
			// @ts-expect-error Enum vs enum value issue
			priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		}) * bundleQuantity;

	const totalPriceWithoutVAT =
		convertToActiveCurrencyIfPriceCurrencyIsDifferent({
			price: priceWithoutVat ?? 0,
			activeCurrency: currency,
			// @ts-expect-error Enum vs enum value issue
			priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		}) * bundleQuantity;

	const originalTotalPriceWithVAT = isDiscounted
		? convertToActiveCurrencyIfPriceCurrencyIsDifferent({
				price: basePrice?.valueWithVat ?? 0,
				activeCurrency: currency,
				// @ts-expect-error Enum vs enum value issue
				priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		  }) * bundleQuantity
		: null;

	const flagSpecification = getFlagSpecification(
		get(bundleData, 'specificationDetails', [])
	);

	const handleOnReturnItemToBasket = useCallback(() => {
		handleOnChangeItemQuantity(bundleQuantity, bundleId);
	}, [bundleId, bundleQuantity, handleOnChangeItemQuantity]);

	const bundle = cartItem?.bundle ?? null;

	return (
		<div
			className={cx('vinisto-user-favorite vinisto-cart__item--mobile', {
				discounted: isDiscounted,
			})}
		>
			<div className="vinisto-user-orders__orders__order-body">
				<div className="vinisto-user-orders__orders__order-body__item">
					<div className="vinisto-user-orders__orders__order-body__item__info__img">
						<img
							src={getBundleImage(
								get(bundleData, 'images', []),
								IMAGE_SIZE_THUMB_64x80
							)}
							alt={`${t({ id: 'alt.bundleImage' })}`}
						/>
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info vinisto-cart-item-info">
						<div className="vinisto-user-orders__orders__order-body__item__info__data">
							<div className="vinisto-user-orders__orders__order-body__item__info__data__name mb-0">
								{getLocalizedValue(get(bundleData, 'name', [])) ?? '-'}
							</div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data w-100">
							<div className="w-100">
								<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
									{get(flagSpecification, 'component') &&
										get(flagSpecification, 'component')}
									<span>
										{get(flagSpecification, 'shortVariety') &&
											get(flagSpecification, 'shortVariety')}
									</span>
								</div>
							</div>
							<div></div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data mt-2 flex-wrap align-items-start">
							<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat w-100">
								{t(
									{ id: 'bundle.supplier.name' },
									{
										name: (
											<span className="vinisto-color-success fw-bolder">
												{supplierName.length > 0
													? supplierName
													: t({
															id: 'productDetail.seller.name.others',
													  })}
											</span>
										),
									}
								)}
							</div>
							<DirectQuantityBox
								bundle={bundle}
								isBasket={true}
							/>
							<div className="vinisto-user-orders__orders__order-body__item__prices">
								<div className="vinisto-user-orders__orders__order-body__item__prices__total">
									{isDiscounted && (
										<div className="vinisto-user-orders__orders__order-body__item__prices__big-price">
											{getLocalizedPrice({
												price: originalTotalPriceWithVAT ?? 0,
												currency,
											})}
										</div>
									)}
									<div className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
										{getLocalizedPrice({
											price: totalPriceWithVAT,
											currency,
										})}
									</div>
								</div>
								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
									{`${t({ id: 'basket.priceWithoutVAT' })} `}
									<span className="fw-bolder price-span">
										{getLocalizedPrice({
											price: totalPriceWithoutVAT,
											currency,
										})}
									</span>
								</div>
							</div>

							<div className="vinisto-user-favorite__delete-wrap">
								<div className="vinisto-user-favorite__delete-wrap__text">
									{t({ id: 'basket.removed.label' })}
									<br />
									<button
										className="vinisto-btn vinisto-clear-btn vinisto-color-success pointer fw-bolder"
										onClick={handleOnReturnItemToBasket}
									>
										{t({ id: 'basket.removed.returnBack' })}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InActiveCartItemMobile;
