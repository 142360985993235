import { dayjsInstance as dayjs } from 'vinisto_shared';
import { AbstractAdapter } from '../abstract-adapter';
import { languages } from '../../shared';
import {
  bundleAdapter,
  bundleEvaluationAdapter,
  bundleItemAdapter,
  bundleSpecificationDetailAdapter,
  imageAdapter,
  orderLimitationAdapter,
  priceAdapter,
  productAdapter,
  supplierAdapter,
  tagAdapter,
} from '../../index';

import { Bundle } from '.';

import {
  VinistoCommonDllModelsApiPricesPriceDiscountSet,
  VinistoCommonDllModelsApiPricesPriceDiscountSupplier,
  VinistoCommonDllModelsApiPricesPriceDiscountVinisto,
  VinistoCommonDllModelsApiPricesPriceDiscountVolume,
  VinistoHelperDllEnumsCurrency,
  VinistoHelperDllEnumsPriceDiscountType,
  VinistoProductDllModelsApiBundleBundle,
} from '@/api-types/product-api';

import convertDateToDayjs from '@/utils/convert-date-to-dayjs';
import { DiscountedPrice, VolumeDiscount } from '../price';
import { VAT_VALUE } from 'vinisto_shared/src/price/get-vat-value';
import { truncateToDecimalPlaces } from 'vinisto_shared/src/price/truncate-to-decimal-places';

const isPriceActive = (
  price:
    | VinistoCommonDllModelsApiPricesPriceDiscountSet
    | VinistoCommonDllModelsApiPricesPriceDiscountSupplier
    | VinistoCommonDllModelsApiPricesPriceDiscountVinisto
) => {
  if (price.validFrom === null && price.validTo === null) return true;

  const validFrom = convertDateToDayjs(price.validFrom);
  const validTo = convertDateToDayjs(price.validTo);
  const isActive = dayjs().isBetween(validFrom, validTo, undefined, '[]');

  return isActive;
};

const isVolumeDiscount = (
  price:
    | VinistoCommonDllModelsApiPricesPriceDiscountSet
    | VinistoCommonDllModelsApiPricesPriceDiscountSupplier
    | VinistoCommonDllModelsApiPricesPriceDiscountVinisto
    | VinistoCommonDllModelsApiPricesPriceDiscountVolume
): price is VinistoCommonDllModelsApiPricesPriceDiscountVolume => {
  return price.type === VinistoHelperDllEnumsPriceDiscountType.VolumeDiscount;
};

const mapApiPricesToBundlePrices = (
  apiData: VinistoProductDllModelsApiBundleBundle,
  request?: {
    currency?:
      | VinistoHelperDllEnumsCurrency
      | keyof typeof VinistoHelperDllEnumsCurrency;
  }
) => {
  // TO CONSIDER This should probably don't have fallback and throw or log an error if currency is not provided
  const activeCurrency = request?.currency ?? VinistoHelperDllEnumsCurrency.CZK;
  const { isSet } = apiData;
  const prices =
    apiData.prices?.filter((price) => price.currency === activeCurrency) ?? [];
  const priceDiscounts =
    apiData.priceDiscounts?.filter(
      (price) => price.currency === activeCurrency
    ) ?? [];

  const activePriceDiscounts = priceDiscounts.filter(isPriceActive);

  const highestAvailableB2cLevelPrice = prices
    .filter((price) => price.level?.startsWith('B2cLevel'))
    .sort((a, b) => {
      const aLevel = Number(a.level?.replace('B2cLevel', ''));
      const bLevel = Number(b.level?.replace('B2cLevel', ''));
      return bLevel - aLevel;
    })[0];

  const basePrice = highestAvailableB2cLevelPrice;

  const b2bPrices =
    apiData.prices
      ?.filter((price) => price.level?.startsWith('B2bLevel'))
      .map((price) => priceAdapter.fromApi(price)) ?? [];

  const vinistoOrSupplierDiscount = activePriceDiscounts.find(
    (
      price
    ): price is
      | VinistoCommonDllModelsApiPricesPriceDiscountSupplier
      | VinistoCommonDllModelsApiPricesPriceDiscountVinisto =>
      price &&
      'type' in price &&
      (price.type === VinistoHelperDllEnumsPriceDiscountType.SupplierDiscount ||
        price.type === VinistoHelperDllEnumsPriceDiscountType.VinistoDiscount)
  );

  const setDiscount = activePriceDiscounts.find(
    (price): price is VinistoCommonDllModelsApiPricesPriceDiscountSet =>
      price &&
      'type' in price &&
      price.type === VinistoHelperDllEnumsPriceDiscountType.SetDiscount
  );

  const volumeDiscount = activePriceDiscounts.find(
    (price): price is VinistoCommonDllModelsApiPricesPriceDiscountVolume =>
      price &&
      'type' in price &&
      price.type === VinistoHelperDllEnumsPriceDiscountType.VolumeDiscount
  );

  if (
    !highestAvailableB2cLevelPrice &&
    !vinistoOrSupplierDiscount &&
    !setDiscount
  ) {
    // eslint-disable-next-line no-console
    console.warn('Missing at least one price or discount');
  }

  const discountedPrice = (() => {
    if (isSet && setDiscount) {
      return {
        ...priceAdapter.fromApiWithDiscount(setDiscount),
        // @todo: once BE starts sending vatValue, remove line below as the priceadapter will handle this value with actual data
        vatValue: basePrice.vatValue ?? VAT_VALUE.BASE_VAT,
      };
    }
    if (vinistoOrSupplierDiscount) {
      return {
        ...priceAdapter.fromApiWithDiscount(vinistoOrSupplierDiscount),
        // @todo: once BE starts sending vatValue, remove line below as the priceadapter will handle this value with actual data
        vatValue: basePrice.vatValue ?? VAT_VALUE.BASE_VAT,
      };
    }
    return null;
  })();

  const volumeDiscountWithCalculatedPricesAndSavings = volumeDiscount
    ? priceAdapter.fromApiWithVolumeDiscount({
        ...volumeDiscount,
        value: Math.max(basePrice.value ?? 0, discountedPrice?.value ?? 0),
        valueWithVat: Math.max(
          basePrice.valueWithVat ?? 0,
          discountedPrice?.valueWithVat ?? 0
        ),
        vatValue: basePrice.vatValue ?? VAT_VALUE.BASE_VAT,
      })
    : null;

  const allDiscountedPrices: (DiscountedPrice | VolumeDiscount)[] =
    priceDiscounts.map((price) => {
      if (isVolumeDiscount(price)) {
        return priceAdapter.fromApiWithVolumeDiscount({
          ...price,
          value: basePrice.value ?? 0,
          valueWithVat: basePrice.valueWithVat ?? 0,
          vatValue: basePrice.vatValue ?? VAT_VALUE.BASE_VAT,
        });
      }
      return {
        ...priceAdapter.fromApiWithDiscount(price),
        // @todo: once BE starts sending vatValue, remove line below as the priceadapter will handle this value with actual data
        vatValue: basePrice.vatValue ?? VAT_VALUE.BASE_VAT,
      };
    });

  const relevantDecimalsForDiscount = activeCurrency === 'CZK' ? 0 : 2;

  const discountDifferenceAsAmount = Number(
    (Number(discountedPrice?.value) - Number(basePrice?.value)).toFixed(
      relevantDecimalsForDiscount
    )
  );
  const discountDifferenceWithVatAsAmount = Number(
    (
      Number(discountedPrice?.valueWithVat) - Number(basePrice?.valueWithVat)
    ).toFixed(relevantDecimalsForDiscount)
  );
  const discountDifferenceAsPercentage =
    (discountDifferenceWithVatAsAmount / Number(basePrice?.valueWithVat)) * 100;
  const isDiscounted =
    !Number.isNaN(discountDifferenceWithVatAsAmount) &&
    discountDifferenceWithVatAsAmount < 0;

  // Controversial - should prevent displaying lower prices than actual selling prices,
  // but it is not an ideal solution. Maybe set a flag and handle it in the UI?
  if (!isDiscounted && discountedPrice) {
    basePrice.value = discountedPrice.value;
    basePrice.valueWithVat = discountedPrice.valueWithVat;
  }

  return {
    // Important: this call has to take place after the basePrice is potentially updated
    // Otherwise the formatting function would't have the correct values
    basePrice: priceAdapter.fromApi(basePrice),
    discountedPrice,
    volumeDiscount: volumeDiscountWithCalculatedPricesAndSavings,
    allDiscountedPrices,
    b2bPrices,
    discountDifferenceAsAmount: Number.isNaN(discountDifferenceAsAmount)
      ? 0
      : discountDifferenceAsAmount,
    discountDifferenceWithVatAsAmount: Number.isNaN(
      discountDifferenceWithVatAsAmount
    )
      ? 0
      : discountDifferenceWithVatAsAmount,
    discountDifferenceAsPercentage: Number.isNaN(discountDifferenceAsPercentage)
      ? 0
      : discountDifferenceAsPercentage,
    isDiscounted,
    ...(setDiscount ? { setItemsPrices: setDiscount.values } : {}),
    currency: activeCurrency as VinistoHelperDllEnumsCurrency,
  };
};

class BundleAdapter extends AbstractAdapter<
  Bundle,
  VinistoProductDllModelsApiBundleBundle
> {
  fromApi(
    apiData: VinistoProductDllModelsApiBundleBundle,
    request?: {
      currency?:
        | VinistoHelperDllEnumsCurrency
        | keyof typeof VinistoHelperDllEnumsCurrency;
    }
  ): Bundle {
    const id = apiData.id;

    if (!id) throw new Error('No id in bundle');

    const orderLimitation =
      apiData.orderLimitation && apiData.orderLimitation.limit !== undefined
        ? orderLimitationAdapter.fromApi(apiData.orderLimitation ?? {})
        : undefined;

    return {
      id,
      name: this.convertMultiLangValue(apiData.name),
      description: this.convertMultiLangValue(apiData.description),
      shortDescription: this.convertMultiLangValue(apiData.shortDescription),
      keywords: this.convertMultiLangValue(apiData.keywords),
      text: this.convertMultiLangValue(apiData.text),
      url: this.convertMultiLangValue(apiData.url),
      language: apiData.language ?? languages[0],
      prices: apiData.prices?.map((price) => priceAdapter.fromApi(price)) ?? [],
      discountedPrices:
        apiData.priceDiscounts?.map((price) =>
          priceAdapter.fromApiWithDiscount(price)
        ) ?? [],
      bundlePrices: mapApiPricesToBundlePrices(apiData, request),
      items: apiData.items?.map((item) => bundleItemAdapter.fromApi(item)),
      categoryIds: apiData.categories,
      products: apiData.productsDetail?.map((product) =>
        productAdapter.fromApi(product)
      ),
      setBundles: apiData.setBundles?.map((bundle) =>
        bundleAdapter.fromApi(bundle, { currency: request?.currency })
      ),
      alternativeBundles: apiData.alternativeBundleObjects?.map((bundle) =>
        bundleAdapter.fromApi(bundle, { currency: request?.currency })
      ),
      tags: apiData.tagsDetail?.map((tag) => tagAdapter.fromApi(tag)),
      images: apiData.images
        ?.map((image) => imageAdapter.fromApi(image))
        .filter((images) => Object.keys(images).length),
      supplier: apiData.supplier
        ? supplierAdapter.fromApi(apiData.supplier)
        : null,
      specificationDetails: apiData.specificationDetails?.map((spec) =>
        bundleSpecificationDetailAdapter.fromApi(spec)
      ),
      bundleEvaluation: bundleEvaluationAdapter.fromApi(
        apiData.bundleEvaluation ?? {}
      ),
      orderLimitation,

      scoring: apiData.scoring ?? 0,
      scoringWarehouse: apiData.scoringWarehouse ?? 0,
      scoringDiscount: apiData.scoringDiscount ?? 0,
      scoringAdmin: apiData.scoringAdmin ?? 0,
      piecesPerPackage: apiData.piecesPerPackage ?? 0,
      packagesOnPallet: apiData.packagesOnPallet ?? 0,
      setType: apiData.setType ?? null,
      warehouseId: apiData.warehouseId ?? [],
      // @ts-expect-error TODO BE should return either states or state, but not both
      state: (apiData.state ? apiData.state : apiData.states?.[0]) ?? '',
      flags: {
        isSet: apiData.isSet ?? false,
        isB2B: apiData.isB2b ?? false,
        isClearanceSale: apiData.isClearanceSale ?? false,
        isDeliveryFree: apiData.isDeliveryFree ?? false,
        isDeleted: apiData.isDeleted ?? false,
        isEnabled: apiData.isEnabled ?? false,
        isForLogged: apiData.isForLogged ?? false,
        isGift: apiData.isGift ?? false,
        isTemporaryUnavailable: apiData.temporaryUnavailable ?? false,
        canSendToWms: apiData.canSendToWms ?? false,
        isSaleOver: apiData.isSaleOver ?? false,
        isApproved: apiData.isApproved ?? false,
      },
      allowedCountries: apiData.allowedCountries ?? [],
      availableCount: apiData.availableCount ?? 0,
    };
  }
}

export default BundleAdapter;
