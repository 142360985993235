import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { LocalizationContext } from 'Services/LocalizationService';
import { DiscountPercentage } from 'vinisto_ui';
import { useDiscountCoupons, useFindBundleInBasket } from 'Pages/Bundle/hooks';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import dayjs from 'dayjs';

import styles from './styles.module.css';

import { Bundle } from '@/domain/bundle';
import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

interface ProductBoxPricesProps {
	bundleData: Bundle | null;
	isLoading?: boolean;
}

const ProductBoxPrices = ({
	bundleData,
	isLoading = false,
}: ProductBoxPricesProps) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();
	const { title: currencyTitle, currency: currencyCode } =
		localizationContext.activeCurrency;

	const bundle = bundleData ?? null;

	const {
		isCouponAvailable,
		priceWhenCouponApplied,
		priceWhenCouponAppliedWithoutVat,
		mostValuableDiscountCouponCode,
	} = useDiscountCoupons({ bundle: bundle ?? null });
	const itemInBasket = useFindBundleInBasket({ bundleId: bundle?.id });

	const bundleLimitPerOrder = getBundleLimitPerOrder(bundle?.orderLimitation);
	const wouldApplyingExceedOrderLimitation =
		typeof bundleLimitPerOrder === 'number' &&
		bundleLimitPerOrder <= (itemInBasket?.quantity ?? 0);

	const bundlePrices = bundle?.bundlePrices ?? null;

	const { basePrice, discountedPrice, isDiscounted } = bundlePrices ?? {};

	const isDiscountEndingSoon =
		discountedPrice?.validTo &&
		dayjs(discountedPrice.validTo).isBefore(dayjs().add(3, 'day'));

	const formattedValidTo = discountedPrice?.validTo
		? dayjs(discountedPrice.validTo).format('D. M.')
		: null;

	return isCouponAvailable && !wouldApplyingExceedOrderLimitation ? (
		<div>
			<div className={styles.originalPrices}>
				<div className={styles.originalPriceWithVat}>
					{basePrice?.getFormatedValueWithVat()}
				</div>
				<div className={styles.originalPrice}>
					{`${t({ id: 'basket.priceWithoutVAT' })} `}{' '}
					{basePrice?.getFormatedValue()}
				</div>
			</div>
			<div className={styles.couponHeading}>
				{`${t({ id: 'productbox.priceWithCoupon' })} `}{' '}
				{mostValuableDiscountCouponCode}
			</div>
			<div className={styles.couponWrap}>
				<div className={styles.discountPriceWrap}>
					<span className={styles.discountPriceWithVat}>
						{t(
							{ id: 'price' },
							{
								value: createCurrencyValue(
									priceWhenCouponApplied ?? 0,
									1,
									currencyCode === VinistoHelperDllEnumsCurrency.EUR ? 2 : 0
								),
								currency: currencyTitle,
							}
						)}
					</span>
					<DiscountPercentage
						discountedPriceWithVat={priceWhenCouponApplied ?? 0}
						standardPriceWithVat={basePrice?.valueWithVat ?? 0}
						className={styles.discountPercentage}
					/>
				</div>
				<div className={styles.discountPrice}>
					{t({ id: 'basket.priceWithoutVAT' })}{' '}
					{t(
						{ id: 'price' },
						{
							value: createCurrencyValue(
								priceWhenCouponAppliedWithoutVat ?? 0,
								1,
								currencyCode === VinistoHelperDllEnumsCurrency.EUR ? 2 : 0
							),
							currency: currencyTitle,
						}
					)}
				</div>
				<div className={styles.couponInfo}>
					{t({ id: 'productbox.couponInfo' })}
				</div>
			</div>
		</div>
	) : isDiscountEndingSoon ? (
		<div className={styles.limited}>
			<div className={styles.limitedHeading}>
				{`${t({ id: 'productbox.limited' })} ${formattedValidTo}`}
			</div>
			<div className={styles.limitedWrap}>
				<div className={styles.originalLimitedPrices}>
					<div className={styles.originalPriceWithVat}>
						{basePrice?.getFormatedValueWithVat()}
					</div>
				</div>
				<div className={styles.discountPriceWrap}>
					<span className={styles.discountPriceWithVat}>
						{discountedPrice?.getFormatedValueWithVat()}
					</span>
					<DiscountPercentage
						discountedPriceWithVat={discountedPrice.valueWithVat ?? 0}
						standardPriceWithVat={basePrice?.valueWithVat ?? 0}
						className={styles.discountPercentage}
					/>
				</div>
				<div className={styles.discountPrice}>
					{t({ id: 'basket.priceWithoutVAT' })}{' '}
					{discountedPrice?.getFormatedValue()}
				</div>
			</div>
		</div>
	) : (
		<>
			<div className="vinisto-wine__price">
				{isLoading ? (
					<Skeleton
						width="100%"
						inline
					/>
				) : (
					<>
						{isDiscounted && (
							<span className="vinisto-wine__price--original">
								{basePrice?.getFormatedValueWithVat()}
							</span>
						)}
						<div className={styles.discountPriceWrap}>
							{isDiscounted
								? discountedPrice?.getFormatedValueWithVat()
								: basePrice?.getFormatedValueWithVat()}
							{isDiscounted && (
								<DiscountPercentage
									discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
									standardPriceWithVat={basePrice?.valueWithVat ?? 0}
									className={styles.discountPercentage}
								/>
							)}
						</div>
					</>
				)}
			</div>
			<div className="vinisto-wine__price--no-vat">
				{isLoading ? (
					<Skeleton
						width="100%"
						inline
					/>
				) : (
					t(
						{
							id: 'carousel.info.withoutVAT',
						},
						{
							priceWithCurrency: isDiscounted
								? discountedPrice?.getFormatedValue()
								: basePrice?.getFormatedValue(),
						}
					)
				)}
			</div>
		</>
	);
};

export default ProductBoxPrices;
