import {
	lazy,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Config from 'Config';
import cx from 'classnames';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, indexOf, invoke, map } from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { getPdfDocument, openPdf } from 'Pages/UserSection/Orders/helpers';
import useAnalytics from 'Hooks/useAnalytics';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { GoPayServiceContext } from 'Services/GoPayService';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { StorageContext } from 'Services/StorageService/context';
import { DeviceServiceContext } from 'Services/DeviceService';
import { ModalContext } from 'Components/Modal/context';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import OrderSummary from 'Pages/CartConfirmation/Components/OrderSummary';
import PaymentInfo from 'Pages/CartConfirmation/Components/PaymentInfo';
import OrderInfoHandover from 'Pages/CartConfirmation/Components/OrderInfoHandover';
import { TrackEvent } from 'Services/FacebookPixel';
import Loader from 'Components/View/Loader';
import { LOGIN_MODAL } from 'Components/Modal/constants';
const BigGreenOkIcon = lazy(() => import('Components/Icons/BigGreenOk'));
const PhoneCallIcon = lazy(() => import('Components/Icons/PhoneCall'));
const BigRedError = lazy(() => import('Components/Icons/BigRedError'));
const MailIcon = lazy(() => import('Components/Icons/MailIcon'));

import '../UserSection/Orders/styles.css';

import {
	ANALYTICS_SENT_STORAGE_KEY,
	GOPAY_PAYMENT_STATUS_ERROR,
	NO_INVOICE_IN_ORDER,
	NOT_FOUND,
	OBJECT_PERMISSION_ERROR,
	ORDER_DOES_NOT_EXIST,
} from './constants';
import RegisterForm from './Components/RegisterForm';
import styles from './styles.module.css';

import './styles.css';
import {
	VinistoHelperDllEnumsCurrency,
	VinistoHelperDllEnumsOrderPaymentType,
	VinistoOrderDllModelsApiOrderOrder,
	VinistoOrderDllModelsApiReturnDataOrderReturn,
} from '@/api-types/order-api';
import { VinistoGopayDllModelsApiPaymentReturn } from '@/api-types/services-api';
import api from '@/api';
import {
	BasketDeleteParams,
	VinistoBasketDllModelsApiReturnDataBasketReturn,
} from '@/api-types/basket-api';
import { ApiError } from '@/domain/error';
import { VinistoHelperDllEnumsOrderOrderState } from '@/api-types/product-api';
import { orderAdapter } from '@/index';

const CartConfirmation = () => {
	const goPayContext = useContext(GoPayServiceContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const { handleOnLoadBasket } = useContext(BasketContext);
	const { useFormatMessage } = useContext(LocalizationContext);
	const { isLoggedIn, anonymousUID, vinistoUser } = useContext(
		AuthenticationContext
	);
	const storageContext = useContext(StorageContext);
	const deviceContext = useContext(DeviceServiceContext);
	const { handleOpenModal } = useContext(ModalContext);

	const navigate = useNavigate();
	const { pushToDataLayer } = useAnalytics();
	const t = useFormatMessage();

	const anonymousUserId = anonymousUID?.anonymousUserId || '';
	const userLoginHash = vinistoUser.loginHash;

	const [goPayPaymentId] = useQueryParam('id');
	const [orderId] = useQueryParam('oid');

	const params = {
		orderId,
		goPayPaymentId,
		userLoginHash,
		anonymousUserId,
		isLoggedIn,
	};

	const deleteBasketAfterOrder = (deleteBasketParams: BasketDeleteParams) =>
		api.delete<VinistoBasketDllModelsApiReturnDataBasketReturn>(
			`basket-api/basket`,
			{
				...deleteBasketParams,
			}
		);

	const deleteBasketMutation = useMutation({
		mutationFn: deleteBasketAfterOrder,
	});

	const goPayPaymentQuery = useQuery(
		['goPayPaymentQuery', params],
		() =>
			api
				.get<VinistoGopayDllModelsApiPaymentReturn>(
					`services-api/gopay/${goPayPaymentId}`
				)
				.then((response) => response.payment)
				.catch((responseError: ApiError) => {
					if (
						responseError.message === ORDER_DOES_NOT_EXIST ||
						responseError.message === GOPAY_PAYMENT_STATUS_ERROR
					) {
						navigate('/');
					}
				}),
		{
			enabled: Boolean(goPayPaymentId),
		}
	);

	const orderQuery = useQuery(
		['orderQuery', params],
		() => {
			const orderId = params.orderId ?? goPayPaymentQuery.data?.orderId;
			const apiPath = isLoggedIn
				? `order-api/orders/${orderId}`
				: `order-api/orders/${orderId}/anonymous/${anonymousUserId}`;
			return api
				.get<VinistoOrderDllModelsApiReturnDataOrderReturn>(
					apiPath,
					isLoggedIn ? { UserLoginHash: userLoginHash } : undefined
				)
				.then(async (response) => {
					if (
						!response.order?.invoice &&
						response.order?.states?.includes(
							VinistoHelperDllEnumsOrderOrderState.PAID
						)
					) {
						throw new Error(NO_INVOICE_IN_ORDER);
					}
					const countryOfSale = response?.order?.countryOfSale ?? 'CZ';
					const orderCurrency = response?.order?.orderCurrency ?? 'CZK';

					await deleteBasketMutation
						.mutateAsync({
							...(isLoggedIn
								? {
										UserLoginHash: userLoginHash,
								  }
								: {
										AnonymousUserId: anonymousUserId,
								  }),
							CountryOfSale: countryOfSale,
							currency: orderCurrency,
						})
						.then(() => handleOnLoadBasket())
						.catch(() => {
							handleShowErrorNotification(
								'notification.message.basketClear.error'
							);
						});
					sendAnalyticsEvent(response.order);
					return response.order ? orderAdapter.fromApi(response.order) : null;
				})
				.catch((responseError: ApiError | Error) => {
					if (responseError.message === NO_INVOICE_IN_ORDER) {
						throw responseError;
					}
					if (
						// @ts-expect-error the error types are currently broken
						(responseError.error ?? []).some(
							(error: any = {}) =>
								error.generalError === OBJECT_PERMISSION_ERROR
						)
					) {
						handleOpenModal(LOGIN_MODAL, {
							showForgottenPasswordLink: false,
							showRegisterCta: false,
							showCloseButton: false,
						});
					}
					if (responseError.message === ORDER_DOES_NOT_EXIST) {
						navigate('/');
					}
				});
		},
		{
			enabled: Boolean(orderId || goPayPaymentQuery.data?.orderId),
			retry: (failureCount, error) => {
				// @ts-expect-error the error types are currently broken
				return error.message === NO_INVOICE_IN_ORDER && failureCount < 5;
			},
		}
	);

	const paymentType =
		(goPayPaymentId
			? goPayPaymentQuery.data?.paymentType
			: orderQuery.data?.payment?.paymentType) ?? null;

	const hasProformaAttached = orderQuery.data?.invoice?.pdfProformaUrl ?? false;
	const hasProformaInvoiceAttached =
		orderQuery.data?.invoice?.pdfVatInvoiceUrl ?? false;
	const hasInvoiceAttached = orderQuery.data?.invoice?.pdfUrl ?? false;

	const handleOnRetryPayment = () =>
		goPayContext.handleOnPayOnline(orderQuery.data ?? {});

	const handleOnClickInvoice = useCallback(
		(orderId: string, documentUrl: string) => async () => {
			try {
				const encodedPdfContent = await getPdfDocument(
					orderId,
					documentUrl,
					userLoginHash
				);
				if (!encodedPdfContent) throw new Error('No pdf content');
				openPdf(encodedPdfContent);
			} catch {
				handleShowErrorNotification('userSection.order.invoice.loadError');
			}
		},
		[handleShowErrorNotification, userLoginHash]
	);

	const handleOnNavigateToOrders = useCallback(() => {
		navigate(
			`/${t({ id: 'routes.user-section.route' })}/${t({
				id: 'routes.user-section.orders.route',
			})}?id=${orderQuery.data?.id}`
		);
	}, [navigate, orderQuery.data?.id, t]);

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.cart.confirmation.name' })}` }
			)}`,
		});
	}, [dispatch, t]);

	const storedAnalyticsSent = useMemo(() => {
		const storedAnalytics = storageContext.StorageService.getStorageItem(
			ANALYTICS_SENT_STORAGE_KEY
		);
		return Array.isArray(storedAnalytics) ? storedAnalytics : [storedAnalytics];
	}, []);

	const [isAnalyticsSent, setIsAnalyticsSent] = useState<boolean>(
		!!orderId && storedAnalyticsSent.includes(orderId)
	);

	const sendAnalyticsEvent = useCallback(
		(data: VinistoOrderDllModelsApiOrderOrder | null | undefined) => {
			if (!data || isAnalyticsSent) return;

			const orderData = data;
			const orderDataId = data.id;

			if (orderId !== orderDataId) {
				const isAlreadySent =
					indexOf(storedAnalyticsSent, orderDataId) !== NOT_FOUND;
				setIsAnalyticsSent(isAlreadySent);
				if (isAlreadySent) {
					return;
				}
			}

			const itemsGaData = map(
				get(orderData, 'orderItems', []),
				(item: Record<string, any>) => {
					return {
						item_id: get(item, 'bundle.id', ''),
						item_name: get(item, 'bundle.name', ''),
						price: get(item, 'bundle.price.value', 0),
						quantity: get(item, 'quantity', 0),
						currency: get(orderData, 'orderCurrency') ?? '',
					};
				}
			);
			const itemsFbData = map(
				get(orderData, 'orderItems', []),
				(item: Record<string, any>) => {
					return {
						id: get(item, 'bundle.id', ''),
						quantity: get(item, 'quantity', 0),
					};
				}
			);

			const purchaseData = {
				email: get(orderData, 'billingAddress.email') ?? '',
				telephone: get(orderData, 'billingAddress.phone') ?? '',
				currency: get(orderData, 'orderCurrency') ?? '',
				transaction_id: get(orderData, 'orderNumber', ''),
				value:
					get(orderData, 'orderPrice', 0) -
					get(orderData, 'delivery.price.value', 0) -
					get(orderData, 'payment.price.value', 0),
				coupon: get(orderData, 'discountCoupon.description', ''),
				items: itemsGaData,
			};
			TrackEvent('track', 'Purchase', {
				content_type: 'product',
				contents: itemsFbData,
				value:
					Math.round((purchaseData.value + Number.EPSILON) * 100) / 100 || 0,
				currency: purchaseData.currency,
			});

			pushToDataLayer({
				event: GA_EVENT.PURCHASE,
				email: purchaseData.email,
				phone_number: purchaseData.telephone,
				ecommerce: {
					transaction_id: purchaseData.transaction_id,
					affiliation: get(Config, 'domainName', ''),
					value: purchaseData.value,
					shipping: get(orderData, 'delivery.price.value', 0),
					currency: purchaseData.currency,
					coupon: purchaseData.coupon,
					items: purchaseData.items,
				},
			});
			setIsAnalyticsSent(true);
			invoke(
				storageContext,
				'StorageService.setItem',
				ANALYTICS_SENT_STORAGE_KEY,
				[...storedAnalyticsSent, get(orderData, 'id')]
			);
		},
		[
			isAnalyticsSent,
			orderId,
			pushToDataLayer,
			storageContext,
			storedAnalyticsSent,
		]
	);

	const orderGiftItems = orderQuery.data?.giftItems?.map((giftItem) => {
		return { bundle: giftItem.bundle, quantity: giftItem.quantity };
	});

	if (
		// Important: `isLoading` would not work here: https://github.com/TanStack/query/issues/3584
		goPayPaymentQuery.isFetching ||
		orderQuery.isLoading ||
		orderQuery.data?.orderNumber == null
	) {
		return (
			<section id="content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className={styles.status}>
								<Skeleton
									width="100px"
									height="100px"
									count={1}
								/>
								<Skeleton
									width="330px"
									height="25px"
									count={1}
									className="vinisto-card__heading vinisto-card__heading--cart-confirmation vinisto-color-success"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<div className="vinisto-confirmation__heading-wrap">
									<Skeleton
										width="300px"
										height="25px"
										count={1}
										className="vinisto-card__heading"
										style={{
											maxWidth: '100%',
										}}
									/>
									<div className="vinisto-confirmation__share desktop-only">
										<Skeleton
											width="230px"
											height="16px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
										<Skeleton
											width="20px"
											height="20px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
										<Skeleton
											width="20px"
											height="20px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
										<Skeleton
											width="20px"
											height="20px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
									</div>
								</div>
								<p className="mb-0 mt-2 vinisto-font-18">
									<Skeleton
										width="550px"
										height="16px"
										count={1}
										className="vinisto-font-18"
										style={{
											maxWidth: '100%',
										}}
									/>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<Skeleton
									width="245px"
									height="25px"
									count={1}
									className="vinisto-card__heading"
									style={{
										maxWidth: '100%',
									}}
								/>
								<Skeleton
									width="490px"
									height="16px"
									count={1}
									className="mb-0 mt-2 vinisto-font-18"
									style={{
										maxWidth: '100%',
									}}
								/>
								<Skeleton
									width="400px"
									height="16px"
									count={1}
									className="mb-0 vinisto-font-18"
									style={{
										maxWidth: '100%',
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<Skeleton
									width="245px"
									height="25px"
									count={1}
									className="vinisto-card__heading"
									style={{
										maxWidth: '100%',
									}}
								/>
								<Skeleton
									width="950px"
									height="16px"
									count={1}
									className="mb-0 mt-2 vinisto-font-18"
									style={{
										maxWidth: '100%',
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	if (
		orderQuery.data?.states?.includes(
			VinistoHelperDllEnumsOrderOrderState.PAID
		) ||
		goPayPaymentQuery.data?.state?.includes(
			VinistoHelperDllEnumsOrderOrderState.PAID
		)
	) {
		return (
			<section id="content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className={styles.status}>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className=""
									/>
								</Suspense>
								<h1 className="vinisto-card__heading vinisto-card__heading--cart-confirmation vinisto-color-success">
									{t({
										id: 'orderConfirmation.success.title',
									})}
								</h1>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className="invisible"
									/>
								</Suspense>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<p className="mb-0 mt-2 vinisto-font-18">
									{t(
										{
											id: 'orderConfirmation.success.text.orderAccepted',
										},
										{
											orderNumber: (
												<span className="fw-bolder">
													{t(
														{
															id: 'orderConfirmation.success.text.orderId',
														},
														{
															value: orderQuery.data?.orderNumber ?? '',
														}
													)}
												</span>
											),
										}
									)}
								</p>
								<p className="mb-0 vinisto-font-18">
									{t(
										{
											id: 'orderConfirmation.success.text.orderState',
										},
										{
											highlightedFollow: (
												<span className="fw-bolder">
													{t({
														id: 'orderConfirmation.success.text.orderState.follow',
													})}
												</span>
											),
											highlightedInvoice: (
												<span className="fw-bolder">
													{t({
														id: 'orderConfirmation.success.text.orderState.invoice',
													})}
												</span>
											),
										}
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
				{isLoggedIn && (
					<div className="container">
						<div className="row">
							<div className="col-12 vinisto-confirmation__ctas">
								<button
									onClick={handleOnNavigateToOrders}
									className="vinisto-btn vinisto-bg vinisto-font-18"
								>
									{t({
										id: 'orderConfirmation.success.followOrderLink',
									})}
								</button>
							</div>
						</div>
					</div>
				)}

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<h1 className="vinisto-card__heading">
									{t({
										id: 'orderConfirmation.success.sms.title',
									})}
								</h1>
								<p className="mb-0 mt-2 vinisto-font-18">
									{t({
										id: 'orderConfirmation.success.sms.text',
									})}
								</p>
							</div>
						</div>
					</div>
				</div>

				{!isLoggedIn && (
					<div className="container">
						<div className="row">
							<div className="col-12">
								<RegisterForm
									email={orderQuery.data?.billingAddress?.email ?? ''}
								/>
							</div>
						</div>
					</div>
				)}
				<OrderSummary
					orderItems={orderQuery.data?.orderItems}
					delivery={orderQuery.data?.delivery}
					orderGiftItems={orderGiftItems}
					payment={orderQuery.data?.payment}
					billingAddress={orderQuery.data?.billingAddress}
					discountCoupons={orderQuery.data?.discountCoupons}
					orderCurrency={
						orderQuery.data?.orderCurrency ?? VinistoHelperDllEnumsCurrency.CZK
					}
					shippingPackaging={orderQuery.data?.shippingPackagingBundle}
				/>
			</section>
		);
	}

	if (
		orderQuery.data?.states?.includes(
			VinistoHelperDllEnumsOrderOrderState.CREATED
		) &&
		(paymentType === VinistoHelperDllEnumsOrderPaymentType.BY_HANDOVER ||
			paymentType === VinistoHelperDllEnumsOrderPaymentType.CASH ||
			paymentType === VinistoHelperDllEnumsOrderPaymentType.BANK_TRANSFER)
	) {
		return (
			<section id="content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className={styles.status}>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className={styles.icon}
									/>
								</Suspense>
								<h1 className={styles.statusHeading}>
									{t({
										id: 'orderConfirmation.created.title',
									})}
								</h1>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className={cx(styles.icon, styles.invisible)}
									/>
								</Suspense>
							</div>
						</div>
					</div>
				</div>

				{paymentType ===
					VinistoHelperDllEnumsOrderPaymentType.BANK_TRANSFER && (
					<PaymentInfo
						orderId={orderQuery.data?.id ?? ''}
						orderNumber={orderQuery.data?.orderNumber ?? ''}
						orderPrice={orderQuery.data?.orderPriceWithVat ?? 0}
						orderEmail={orderQuery.data?.billingAddress?.email ?? ''}
					/>
				)}

				{paymentType === VinistoHelperDllEnumsOrderPaymentType.BY_HANDOVER &&
					orderQuery.data?.orderNumber && (
						<OrderInfoHandover
							orderNumber={orderQuery.data?.orderNumber}
							orderEmail={orderQuery.data?.billingAddress?.email ?? ''}
						/>
					)}

				{isLoggedIn && (
					<div className="container">
						<div className="row">
							<div className="col-12 vinisto-confirmation__ctas">
								<button
									onClick={handleOnNavigateToOrders}
									className={cx(
										'vinisto-btn vinisto-bg-green',
										styles.checkStateButton
									)}
								>
									{t({
										id: 'orderConfirmation.success.followOrderLink',
									})}
								</button>
								<div className="d-inline-block ms-0 ms-xl-3">
									{hasProformaAttached && (
										<button
											className="vinisto-btn bg-transparent color-primary vinisto-font-18 px-4"
											onClick={handleOnClickInvoice(
												orderQuery.data?.id ?? '',
												orderQuery.data?.invoice?.pdfProformaUrl ?? ''
											)}
										>
											{t(
												{
													id: 'orderConfirmation.success.proformaLink',
												},
												{
													value: orderQuery.data?.orderNumber ?? '',
												}
											)}
										</button>
									)}

									{hasProformaInvoiceAttached && (
										<button
											className="vinisto-btn bg-transparent color-primary vinisto-font-18 px-3"
											onClick={handleOnClickInvoice(
												orderQuery.data?.id ?? '',
												orderQuery.data?.invoice?.pdfVatInvoiceUrl ?? ''
											)}
										>
											{t(
												{
													id: 'orderConfirmation.success.proformaInvoiceLink',
												},
												{
													value: orderQuery.data?.orderNumber ?? '',
												}
											)}
										</button>
									)}

									{hasInvoiceAttached && (
										<button
											className="vinisto-btn bg-transparent color-primary vinisto-font-18 px-3"
											onClick={handleOnClickInvoice(
												orderQuery.data?.id ?? '',
												orderQuery.data?.invoice?.pdfUrl ?? ''
											)}
										>
											{t(
												{
													id: 'orderConfirmation.success.invoiceLink',
												},
												{
													value: orderQuery.data?.orderNumber ?? '',
												}
											)}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				<OrderSummary
					orderItems={orderQuery.data?.orderItems}
					orderGiftItems={orderGiftItems}
					delivery={orderQuery.data?.delivery}
					payment={orderQuery.data?.payment}
					billingAddress={orderQuery.data?.billingAddress}
					discountCoupons={orderQuery.data?.discountCoupons}
					orderCurrency={
						orderQuery.data?.orderCurrency ?? VinistoHelperDllEnumsCurrency.CZK
					}
					shippingPackaging={orderQuery.data?.shippingPackagingBundle}
				/>
			</section>
		);
	}

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="vinisto-confirmation__status">
							<Suspense fallback={<Loader blank />}>
								<BigRedError
									id="cart-onfirmation-err-ico"
									alt=""
									title=""
									className=""
								/>
							</Suspense>
							<h1 className="vinisto-card__heading vinisto-card__heading--cart-confirmation vinisto-color-error text-center">
								{t({ id: 'orderConfirmation.fail.title' })}
							</h1>
							<Suspense fallback={<Loader blank />}>
								<BigRedError
									id="cart-onfirmation-err-ico"
									alt=""
									title=""
									className={
										deviceContext.isTablet || deviceContext.isMobile
											? 'invisible'
											: ''
									}
								/>
							</Suspense>
						</div>
						<ContainerFullWidth>
							<h2 className="vinisto-heading underline mt-2 mt-xl-4">
								{t({
									id: 'cartShippingPayment.info',
								})}
							</h2>
							<div className={styles.info}>
								<p className={styles.infoText}>
									{t(
										{
											id: 'orderConfirmation.fail.info',
										},
										{
											orderNumber: (
												<span className={styles.boldText}>
													{t(
														{
															id: 'orderConfirmation.fail.info.orderNumber',
														},
														{
															value: orderQuery.data?.orderNumber,
														}
													)}
												</span>
											),
											error: (
												<span className={styles.errorText}>
													{t({
														id: 'orderConfirmation.fail.info.error',
													})}
												</span>
											),
											noExpedition: (
												<span className={styles.errorText}>
													{t({
														id: 'orderConfirmation.fail.info.noExpedition',
													})}
												</span>
											),
										}
									)}
								</p>
								<div className="py-3">
									<button
										onClick={handleOnRetryPayment}
										className="vinisto-btn vinisto-bg-green vinisto-payment-error-btn"
									>
										{t({
											id: 'orderConfirmation.fail.repeat.btn',
										})}
									</button>
								</div>
								<p className={styles.infoText}>
									{t({
										id: 'orderConfirmation.fail.info.moreInfo',
									})}
								</p>
								<div className={styles.contactUs}>
									<a
										href={`tel:${PHONE_NUMBER}`}
										className={styles.linkTo}
									>
										<Suspense fallback={<Loader blank />}>
											<PhoneCallIcon className={styles.contactUsPhoneIcon} />
										</Suspense>
										{formatPhoneNumber(PHONE_NUMBER)}{' '}
										{t({
											id: 'orderConfirmation.fail.info.opening',
										})}
									</a>
									<a
										href={`mailto:${Config.market.supportEmail}`}
										className={styles.linkTo}
									>
										<Suspense fallback={<Loader blank />}>
											<MailIcon className={styles.contactUsMailIcon} />
										</Suspense>
										{Config.market.supportEmail}
									</a>
								</div>
							</div>
						</ContainerFullWidth>
						<OrderSummary
							orderItems={orderQuery.data?.orderItems}
							orderGiftItems={orderGiftItems}
							delivery={orderQuery.data?.delivery}
							payment={orderQuery.data?.payment}
							billingAddress={orderQuery.data?.billingAddress}
							discountCoupons={orderQuery.data?.discountCoupons}
							orderCurrency={
								orderQuery.data?.orderCurrency ??
								VinistoHelperDllEnumsCurrency.CZK
							}
							shippingPackaging={orderQuery.data?.shippingPackagingBundle}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CartConfirmation;
