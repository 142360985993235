import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import CarouselSection from 'Components/CarouselSection';
import DeliveryMethods from 'Components/DeliveryMethods';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import BundleTag from 'Components/ProductBox/Components/BundleTag';
import ButtonAddToFavorites from 'Components/ProductBox/Components/ButtonAddToFavorites';
import ProductBasketCount from 'Components/ProductBox/Components/ProductBasketCount';
import { useProductBasketCount } from 'Components/ProductBox/hooks';
import Rating from 'Components/Rating';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Config from 'Config';
import useIdenticalBundles from 'Hooks/Queries/useIdenticalBundles';
import BannerListProduct from 'Pages/Bundle/Components/BundleDetail/Components/BundleBanner';
import CustomerSupport from 'Pages/Bundle/Components/BundleDetail/Components/CustomerSupport';
import PaymentItem from 'Pages/Bundle/Components/BundleDetail/Components/PaymentItem';
import SellInfo from 'Pages/Bundle/Components/BundleDetail/Components/SellInfo';
import SellerInfo from 'Pages/Bundle/Components/BundleDetail/Components/SellerInfo';
import { IBundleDetailProps } from 'Pages/Bundle/interfaces';
import ArticleTabs from 'Pages/Home/Components/ArticleTabs';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { DeviceServiceAction } from 'Services/DeviceService/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import cx from 'classnames';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useMatch } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import useFavoriteItem from 'Components/ProductBox/Components/ButtonAddToFavorites/hook';
import { SommelierRating } from 'vinisto_ui';
import { ShareProductLink } from 'vinisto_ui';
import { useQuery } from '@tanstack/react-query';
import SetBundle from 'Pages/Bundle/Components/BundleDetail/Components/SetBundle';
import {
	MYSTERY_BOX_ID,
	SOMMELIER_RATING_ID,
	TYP_PRODUKTU_ID,
} from 'Pages/Bundle/constants';
import SpecificationService from 'vinisto_api_client/src/product-service/specification';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';
import { useDiscountCoupons, useFindBundleInBasket } from 'Pages/Bundle/hooks';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';
import { useBundlesByTag } from 'Hooks/Queries/useBundlesByTag';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import { getSetBundles } from 'Pages/Bundle/hooks/use-bundle-detail';
import TextHighlighted from 'Components/View/TextHighlighted';

import LinkWidget from '../../../Home/Components/LinkWidget';

import DiscountCoupon from './Components/DiscountCoupon';
import BundleBreadcrumb from './Components/BundleBreadcrumb';
import BundleInfoParams from './Components/BundleInfoParams';
import OtherSellerOffers from './Components/OtherSellerOffers';
import Profile from './Components/Profile';
import ReviewSection from './Components/ReviewSection';
import RichText from './Components/RichText';
import {
	BundleItemVariants,
	QuantityBoxVariants,
} from './Components/ShopControls/Components/BundleItem/constants';
import SpecificationExplanationView from './Components/SpecificationExplanationView';
import { useSpecificationExplanation } from './Components/SpecificationExplanationView/hook';
import VolumeDiscount from './Components/VolumeDiscount';
import GiftSection from './Components/GiftSection';
import DesktopShopControlsSection from './DesktopShopControlsSection';
import BundleItem from './Components/ShopControls/Components/BundleItem';
import BlogSectionWrapper from './Components/BlogSectionWrapper';
import BundleNotes from './Components/BundleNotes';
import Info from './Components/BundleNotes/Components/Info';
import styles from './styles.module.css';

import {
	VinistoOrderDllModelsApiDeliveryDelivery,
	VinistoOrderDllModelsApiPaymentPayment,
} from '@/api-types/order-api';
import { Allowed_Sections } from '@/domain/link-widget/enums';
import {
	VinistoHelperDllEnumsBundleSortableColumns,
	VinistoHelperDllEnumsCountryCode,
	VinistoHelperDllEnumsCurrency,
} from '@/api-types/product-api';
import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';

const CAROUSEL_BUNDLES_TAG_ID = '6704eab6586c877f69234616';

const BundleDetail = ({
	bundle,
	sortedBundleImagesInOriginalFormatAndResolution,
	bundleMeta,
	carouselData,
	categoriesData,
	carouselSupplierData,
	deliveriesData,
	paymentsData,
	refreshData,
}: IBundleDetailProps) => {
	const localizationContext = useContext(LocalizationContext);
	const { activeCurrency, countryOfSale } = localizationContext;
	const { currency } = activeCurrency;
	const {
		footerHeight,
		dispatch,
		sellerSelectionFooterHeight,
		isMobile,
		isTablet,
		isDesktop,
	} = useContext(DeviceServiceContext);
	const basketContext = useContext(BasketContext);
	const warehouseContext = useContext(WarehouseContext);
	const t = localizationContext.useFormatMessage();
	const parentRef = useRef<HTMLDivElement>(null);
	const sellerSelectionRef = useRef<HTMLDivElement>(null);
	const [topDistance, setTopDistance] = useState(0);
	const [open, setOpen] = useState(false);
	const bundleTotalRatingCount =
		bundle.bundleEvaluation?.totalEvaluationCount ?? 0;

	const {
		data: identicalBundlesData,
		isLoading: isIdenticalBundlesDataLoading,
	} = useIdenticalBundles(bundle.id, {
		currency,
		countryOfSale: countryOfSale as VinistoHelperDllEnumsCountryCode,
	});

	const {
		bundleName,
		bundleShortDescription,
		bundleDescription,
		bundleImageSmall,
	} = bundleMeta;
	const { isGift, isSet, isTemporaryUnavailable, isForLogged } = bundle.flags;

	const {
		shortVariety: bundleProducerName,
		varietyUrl: bundleProducerUrl,
		component: bundleFlag,
	} = getFlagSpecification(bundle.specificationDetails ?? []);

	const bundleAverageRating = (bundle.bundleEvaluation?.averageStars ?? 0) / 2;

	const { basketQuantityPopover } = useProductBasketCount(bundle);

	const lastViewedBundles = useMemo(() => {
		const id = bundle?.id ?? '';
		return carouselData?.lastViewedBundles?.filter(
			(bundle) => bundle?.id !== id
		);
	}, [bundle?.id, carouselData?.lastViewedBundles]);

	const [isStickyBarOpen, setIsStickyBarOpen] = useState(false);

	const isOnlySupplier = identicalBundlesData?.length === 0;

	useEffect(() => {
		setIsStickyBarOpen(isOnlySupplier ?? false);
	}, [isOnlySupplier]);

	const handleOpenGallery = useCallback(() => {
		if ((sortedBundleImagesInOriginalFormatAndResolution ?? []).length > 0) {
			setOpen(true);
		}
	}, [sortedBundleImagesInOriginalFormatAndResolution]);

	const { basePrice, discountedPrice, isDiscounted } = bundle.bundlePrices;

	const { handleAddItemToFavorites, handleRemoveItemFromFavorites } =
		useFavoriteItem(bundle.id, {
			currency:
				bundle?.prices?.[0]?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
			price: (isDiscounted ? discountedPrice?.value : basePrice?.value) ?? 0,
			item_name: bundleName,
		});

	useEffect(() => {
		if (parentRef.current) {
			setTopDistance(
				window.pageYOffset + parentRef.current?.getBoundingClientRect()?.top
			);
		}
	}, [parentRef.current]);

	const matchBundleDetail = useMatch(`/${t({ id: 'routes.product.route' })}/*`);

	useEffect(() => {
		if (matchBundleDetail === null) {
			return;
		}

		dispatch([
			DeviceServiceAction.setSellerSelectionFooterHeight,
			(sellerSelectionRef?.current?.offsetHeight as number) + footerHeight,
		]);
	}, [
		footerHeight,
		sellerSelectionFooterHeight,
		isStickyBarOpen,
		matchBundleDetail,
		dispatch,
	]);

	const allBundles = isIdenticalBundlesDataLoading
		? []
		: [bundle, ...(identicalBundlesData ?? [])];

	const isQuantityLoading = allBundles?.some(
		(bundle) => warehouseContext.getQuantity(bundle?.id ?? '') === undefined
	);

	const bundlesSortedByAvailabilityAndPrice = allBundles?.sort((a, b) => {
		const aPurchasability =
			a?.flags.isTemporaryUnavailable || a?.flags.isGift ? 1 : 0;
		const bPurchasability =
			b?.flags.isTemporaryUnavailable || b?.flags.isGift ? 1 : 0;

		const aAvailability = warehouseContext.getQuantity(a?.id ?? '') ? 1 : 0;
		const bAvailability = warehouseContext.getQuantity(b?.id ?? '') ? 1 : 0;

		const aLowestPriceWithVat = a.bundlePrices.isDiscounted
			? a.bundlePrices.discountedPrice?.valueWithVat ?? 0
			: a.bundlePrices.basePrice.valueWithVat;

		const bLowestPriceWithVat = b.bundlePrices.isDiscounted
			? b.bundlePrices.discountedPrice?.valueWithVat ?? 0
			: b.bundlePrices.basePrice.valueWithVat;

		const aAvailableQuantity = warehouseContext.getQuantity(a?.id ?? '') ?? 0;
		const bAvailableQuantity = warehouseContext.getQuantity(b?.id ?? '') ?? 0;

		return (
			// Sort by purchaseability (temporary unavailable/gift goes down the list)
			aPurchasability - bPurchasability ||
			// Sort by availability
			bAvailability - aAvailability ||
			// Sort by price
			aLowestPriceWithVat - bLowestPriceWithVat ||
			// Sort by available quantity
			bAvailableQuantity - aAvailableQuantity
		);
	});

	const { data: bundlesByTag, isLoading: isLoadingBundlesByTag } =
		useBundlesByTag(CAROUSEL_BUNDLES_TAG_ID, {
			...DEFAULT_BUNDLE_API_PARAMS,
			isInStock: true,
			sortingColumn: VinistoHelperDllEnumsBundleSortableColumns.SCORING,
		});

	// cheapestAvailableBundle can be undefined! Unchecked index access here
	const cheapestAvailableBundle = bundlesSortedByAvailabilityAndPrice[0];

	const otherBundles = bundlesSortedByAvailabilityAndPrice
		.slice(1)
		.filter(
			(bundle) => !bundle.flags.isTemporaryUnavailable && !bundle.flags.isGift
		);

	const handleScrollToProfile = (isMobile = false) => {
		const reviewSection = document.getElementById(
			'review-section-' + (isMobile ? 'mobile' : 'desktop')
		);
		const scrollOffset = isMobile ? 50 : 150;
		reviewSection &&
			window.scrollTo({
				top: reviewSection.offsetTop - scrollOffset,
				behavior: 'smooth',
			});
	};

	const bundle_set_products = getSetBundles({ bundle });

	const { data: sommelierRatingSpecificationValues } = useQuery(
		['bundle-specification-values', bundle.id, SOMMELIER_RATING_ID],
		() =>
			SpecificationService.getSpecificationValueForBundle(
				bundle.id,
				SOMMELIER_RATING_ID
			)
	);

	const sommelierRating = sommelierRatingSpecificationValues?.[0]?.value;

	const specificationExplanationData = useSpecificationExplanation(
		bundle.specificationDetails ?? []
	);

	const volumeDiscount = bundle.bundlePrices?.volumeDiscount;

	const bundleLimitPerOrder = getBundleLimitPerOrder(bundle?.orderLimitation);

	const { isLoggedIn, vinistoUser } = useContext(AuthenticationContext);
	const { handleOpenModal } = useContext(ModalContext);

	const availableQuantity = warehouseContext.getQuantity(bundle?.id ?? '') ?? 0;

	const {
		isCouponAvailable,
		priceWhenCouponApplied,
		priceWhenCouponAppliedWithoutVat,
		mostValuableDiscountCouponCode,
		mostValuableDiscountCoupon,
		handleOnAddToBasketWithDiscountCoupon,
	} = useDiscountCoupons({
		bundle,
		showCouponsForRegisterdUsersIfUserIsNotLoggedIn: true,
	});

	const itemInBasket = useFindBundleInBasket({ bundleId: bundle?.id });

	const wouldApplyingExceedOrderLimitation =
		typeof bundleLimitPerOrder === 'number' &&
		bundleLimitPerOrder <= (itemInBasket?.quantity ?? 0);

	const hasToLoginToBeAbleToPurchase =
		mostValuableDiscountCoupon?.isForRegisteredUsers && !isLoggedIn;

	const handleOpenLoginModal = () => {
		handleOpenModal(LOGIN_MODAL);
	};

	const isSetDetailVisible = !bundle.specificationDetails?.some((spec) => {
		if (
			spec.definition.id == TYP_PRODUKTU_ID &&
			// @ts-expect-error wrongly typed specifications
			spec?.value?.selectedValuesName?.includes(MYSTERY_BOX_ID)
		) {
			return true;
		}
		return false;
	});

	return (
		<section
			id="content-wrapper"
			className={styles.section}
		>
			<Lightbox
				carousel={{
					finite: true,
				}}
				plugins={[Zoom]}
				open={Config.galleryEnabled ? open : false}
				close={() => setOpen(false)}
				styles={{ container: { backgroundColor: 'rgba(#000, .5)' } }}
				slides={sortedBundleImagesInOriginalFormatAndResolution}
			/>
			<ContainerFullWidth containerClassName="mt-3 position-relative z-1">
				<div className={styles.bundle}>
					<div
						className={styles.bundleInfo}
						ref={parentRef}
					>
						<BundleBreadcrumb
							categories={categoriesData}
							bundleName={bundleName}
							className="mb-2"
						/>
						<div>
							{(isMobile || isTablet) && (
								<>
									<p className={styles.bundleVariety}>
										{
											<BundleProducer
												flag={bundleFlag}
												name={bundleProducerName}
											/>
										}
										<ShareProductLink
											className="h-100 mb-auto"
											bundleName={bundleName}
											isTabletMobile={isMobile || isTablet}
										/>
									</p>
									<div className={styles.bundleNameWrapper}>
										<h1
											className={styles.bundleName}
											dangerouslySetInnerHTML={{
												__html: bundleName,
											}}
										></h1>
									</div>
									<div>
										{Boolean(bundleAverageRating && isSet) && (
											<div className="d-flex align-center my-2 pe-2">
												<div className={styles.bundleRating}>
													<Rating
														defaultValue={bundleAverageRating}
														handleOnClick={() => handleScrollToProfile(true)}
														readOnly
													/>

													<button
														className={styles.bundleRatingCount}
														onClick={() => handleScrollToProfile(true)}
													>
														({bundleTotalRatingCount})
													</button>
												</div>
											</div>
										)}
										{typeof sommelierRating === 'number' && (
											<SommelierRating
												rating={sommelierRating}
												label={t({ id: 'bundle.sommelierRating' })}
												className="ms-auto"
												translations={{
													info: t(
														{ id: 'sommelier.info' },
														{
															vinisto: (
																<TextHighlighted className="fw-bolder">
																	vinisto
																</TextHighlighted>
															),
														}
													),
													explanation: t({ id: 'sommelier.explanation' }),
													firstPoints: t({ id: 'sommelier.firstPoints' }),
													firstDesc: t({ id: 'sommelier.firstDesc' }),
													secondPoints: t({ id: 'sommelier.secondPoints' }),
													secondDesc: t({ id: 'sommelier.secondDesc' }),
													thirdPoints: t({ id: 'sommelier.thirdPoints' }),
													thirdDesc: t({ id: 'sommelier.thirdDesc' }),
													fourthPoints: t({ id: 'sommelier.fourthPoints' }),
													fourthDesc: t({ id: 'sommelier.fourthDesc' }),
													fifthPoints: t({ id: 'sommelier.fifthPoints' }),
													fifthDesc: t({ id: 'sommelier.fifthDesc' }),
												}}
											/>
										)}
									</div>

									<span className={cx(styles.bundleLabelsMobile)}>
										{bundle?.tags?.map((tagDetail, index) => (
											<BundleTag
												{...{ tagDetail }}
												key={`tag-desktop-${tagDetail.id ?? index}`}
												className={styles.bundleLabel}
											/>
										))}
									</span>
								</>
							)}

							<button
								onClick={handleOpenGallery}
								className={styles.bundleImageWrap}
							>
								{isDesktop && bundle?.tags && bundle?.tags?.length > 0 && (
									<div
										className={cx(
											styles.bundleLabels,
											styles.bundleLabelsDesktop
										)}
									>
										{bundle?.tags?.map((tagDetail, index) => (
											<BundleTag
												{...{ tagDetail }}
												key={`tag-desktop-${tagDetail.id ?? index}`}
												className={styles.bundleLabel}
											/>
										))}
									</div>
								)}
								<img
									className={styles.bundleImage}
									src={bundleImageSmall}
									alt={`${t({
										id: 'alt.bundleImage',
									})}`}
									width={368}
									height={490}
									/* eslint-disable-next-line react/no-unknown-property */
									fetchpriority="high"
								/>
								{!isTemporaryUnavailable && !isGift && (
									<ButtonAddToFavorites
										itemId={bundle.id ?? ''}
										addToFavorites={handleAddItemToFavorites}
										removeItemFromFavorites={handleRemoveItemFromFavorites}
										className={styles.addToFavorites}
										size="bundle"
									/>
								)}
								<ProductBasketCount
									text={basketQuantityPopover}
									className={styles.infoBox}
								/>
							</button>

							{(isMobile || isTablet) && (
								<>
									{bundleShortDescription && (
										<p className={styles.bundleShortDescription}>
											{bundleShortDescription}
										</p>
									)}
									<SellInfo
										supplierName={bundle.supplier?.nameWeb ?? ''}
										deliveryDate={warehouseContext.deliveryDate}
										availableQuantity={availableQuantity}
									/>
									{isCouponAvailable && !wouldApplyingExceedOrderLimitation ? (
										<DiscountCoupon
											basePrice={basePrice}
											discountedPrice={discountedPrice}
											priceWhenCouponApplied={priceWhenCouponApplied}
											priceWhenCouponAppliedWithoutVat={
												priceWhenCouponAppliedWithoutVat
											}
											isDiscounted={isDiscounted}
											hasToLoginToBeAbleToPurchase={
												!!hasToLoginToBeAbleToPurchase
											}
											mostValuableDiscountCoupon={mostValuableDiscountCoupon}
											mostValuableDiscountCouponCode={
												mostValuableDiscountCouponCode
											}
											handleOnAddToBasketWithDiscountCoupon={
												handleOnAddToBasketWithDiscountCoupon
											}
											availableQuantity={availableQuantity}
										/>
									) : null}
									{volumeDiscount !== null && (
										<VolumeDiscount
											bundleId={bundle.id}
											isForLogged={isForLogged}
											volumeDiscount={volumeDiscount}
											warehouseCount={
												warehouseContext.getQuantity(bundle.id) ?? 0
											}
											bundleLimitPerOrder={bundleLimitPerOrder}
										/>
									)}
									{basketContext?.minimalPriceForFreeDelivery !== null && (
										<GiftSection bundleId={bundle.id} />
									)}
								</>
							)}

							{isDesktop && (
								<>
									<div className={styles.bundleAbout}>
										<h2 className={styles.bundleHeadingMain}>
											{t({ id: 'bundle.aboutBundle' })}
										</h2>

										<RichText
											className={cx(styles.bundleAboutText)}
											content={bundleDescription}
										/>
									</div>
									<div className={styles.bundleAbout}>
										<h2 className={styles.bundleHeading}>
											{t({ id: 'bundle.bundleProperties' })}
										</h2>
										<div className={styles.bundleParams}>
											<BundleInfoParams
												bundleParams={bundle?.specificationDetails ?? []}
											/>
										</div>
									</div>

									{isSet && isSetDetailVisible && (
										<div className={styles.bundleAbout}>
											<h2 className={styles.bundleHeading}>
												{t({ id: 'bundle.bundleCollection' })}
											</h2>
											<div className={styles.bundleSets}>
												{bundle_set_products.map((bundle_set, index) =>
													bundle_set.setItems.map((setItem) =>
														[...Array(setItem.amount)].map(() => (
															<SetBundle
																{...{ ...bundle_set, setItem: setItem }}
																key={index}
															/>
														))
													)
												)}
											</div>
										</div>
									)}

									<div id="review-section-desktop"></div>
									{Boolean(bundle?.bundleEvaluation?.totalEvaluationCount) && (
										<Profile
											evaluation={bundle?.bundleEvaluation ?? {}}
											isLoading={false}
										/>
									)}
									{specificationExplanationData &&
										!!specificationExplanationData.length && (
											<SpecificationExplanationView
												data={specificationExplanationData}
												className={cx({
													[styles.bundleAbout]: !isSet,
												})}
											/>
										)}
									<BlogSectionWrapper bundleId={bundle.id} />
								</>
							)}

							{isDesktop && (
								<>
									{bundle.id && vinistoUser.email && (
										<BundleNotes bundleId={bundle.id} />
									)}
									{!isLoggedIn && (
										<div className={styles.noteWrap}>
											<h2 className={styles.noteHeading}>
												{t({
													defaultMessage: 'Vaše poznámky k produktu',
													id: 'bundle.notes.form.heading',
												})}
												<Info />
											</h2>
											<p>
												{t({
													id: 'bundle.notes.loginToComment.subtitle',
												})}
											</p>
											<button
												className={cx('vinisto-btn', styles.noteBtn)}
												onClick={handleOpenLoginModal}
											>
												{t({ id: 'productDetail.btn.login' })}
											</button>
										</div>
									)}
									{!isSet && (
										<ReviewSection
											{...{ bundle }}
											isLoading={false}
											refreshBundleData={refreshData}
											className={styles.bundleReview}
										/>
									)}
								</>
							)}

							<div className="vinisto-right-info-tablet-mobile">
								{(isMobile || isTablet) && (
									<>
										<BannerListProduct />

										{paymentsData?.length > 0 && (
											<>
												<p className={styles.paymentWrapperHeading}>
													{t({ id: 'productDetail.paymentMethods' })}
												</p>

												<div className="bundle-detail-payment">
													<div className={styles.paymentMethods}>
														{paymentsData?.map(
															(
																payment: VinistoOrderDllModelsApiPaymentPayment
															) => (
																<PaymentItem
																	key={payment?.id}
																	payment={payment}
																/>
															)
														)}
													</div>
												</div>
											</>
										)}

										{deliveriesData?.length > 0 && (
											<DeliveryMethods
												deliveries={
													deliveriesData as VinistoOrderDllModelsApiDeliveryDelivery[]
												}
												showIcons={false}
											/>
										)}

										<CustomerSupport />

										{otherBundles.length > 0 && (
											<OtherSellerOffers otherOffers={otherBundles} />
										)}

										<div className="vinisto-product-detail-beseller-info">
											<SellerInfo />
										</div>

										<h2 className={styles.bundleHeadingMain}>
											{t({ id: 'bundle.aboutBundle' })}
										</h2>
										<div className={styles.bundleAboutText}>
											<RichText
												className={cx(styles.bundleAboutText)}
												content={bundleDescription}
											/>
										</div>

										<div className={styles.bundleAbout}>
											<h2 className={styles.bundleHeading}>
												{t({ id: 'bundle.bundleProperties' })}
											</h2>
											<div className={styles.bundleParams}>
												<BundleInfoParams
													bundleParams={bundle?.specificationDetails ?? []}
												/>
											</div>
										</div>

										{isSet && (
											<div className={styles.bundleAbout}>
												<h2 className={styles.bundleHeading}>
													{t({ id: 'bundle.bundleCollection' })}
												</h2>
												<div className={styles.bundleSets}>
													{bundle_set_products.map((bundle_set, index) =>
														bundle_set.setItems.map((setItem) =>
															[...Array(setItem.amount)].map(() => (
																<SetBundle
																	{...{ ...bundle_set, setItem: setItem }}
																	key={index}
																/>
															))
														)
													)}
												</div>
											</div>
										)}

										<div id="review-section-mobile"></div>
										{Boolean(bundle.bundleEvaluation?.totalEvaluationCount) && (
											<Profile
												evaluation={bundle?.bundleEvaluation ?? {}}
												isLoading={false}
											/>
										)}

										{specificationExplanationData &&
											!!specificationExplanationData.length && (
												<SpecificationExplanationView
													data={specificationExplanationData}
													className={styles.bundleAbout}
												/>
											)}
										<BlogSectionWrapper bundleId={bundle.id} />
										{bundle.id && vinistoUser.email && (
											<BundleNotes bundleId={bundle.id} />
										)}
										{!isLoggedIn && (
											<div className={styles.noteWrap}>
												<h2 className={styles.noteHeading}>
													{t({
														defaultMessage: 'Vaše poznámky k produktu',
														id: 'bundle.notes.form.heading',
													})}
													<Info />
												</h2>
												<p>
													{t({
														id: 'bundle.notes.loginToComment.subtitle',
													})}
												</p>
												<button
													className={cx('vinisto-btn', styles.noteBtn)}
													onClick={handleOpenLoginModal}
												>
													{t({ id: 'productDetail.btn.login' })}
												</button>
											</div>
										)}
										{!isSet && (
											<ReviewSection
												bundle={bundle}
												isLoading={false}
												refreshBundleData={refreshData}
												className={cx(styles.bundleAbout, styles.reviewSection)}
											/>
										)}
									</>
								)}
							</div>
						</div>
					</div>

					{isDesktop && (
						<div className={styles.bundleShopCol}>
							<div
								style={{
									position: 'sticky',
									top: topDistance,
								}}
							>
								<p className={styles.bundleVariety}>
									{
										<BundleProducer
											flag={bundleFlag}
											name={bundleProducerName}
											url={bundleProducerUrl}
										/>
									}
									<ShareProductLink
										className="h-100 mb-auto mt-0"
										bundleName={bundleName}
										isTabletMobile={isMobile || isTablet}
									/>
								</p>
								<div className={styles.bundleNameWrapper}>
									<h1
										className={styles.bundleName}
										dangerouslySetInnerHTML={{
											__html: bundleName,
										}}
									></h1>
								</div>
								<div className={styles.ratingWrap}>
									{Boolean(bundleAverageRating && !isSet) && (
										<div className="pe-2">
											<div className={styles.bundleRating}>
												<Rating
													defaultValue={bundleAverageRating}
													handleOnClick={() => handleScrollToProfile()}
													readOnly
												/>
												<button
													className={styles.bundleRatingCount}
													onClick={() => handleScrollToProfile()}
												>
													({bundleTotalRatingCount})
												</button>
											</div>
										</div>
									)}
									{typeof sommelierRating === 'number' && (
										<SommelierRating
											rating={sommelierRating}
											label={t({ id: 'bundle.sommelierRating' })}
											translations={{
												info: t(
													{ id: 'sommelier.info' },
													{
														vinisto: (
															<TextHighlighted className="fw-bolder">
																vinisto
															</TextHighlighted>
														),
													}
												),
												explanation: t({ id: 'sommelier.explanation' }),
												firstPoints: t({ id: 'sommelier.firstPoints' }),
												firstDesc: t({ id: 'sommelier.firstDesc' }),
												secondPoints: t({ id: 'sommelier.secondPoints' }),
												secondDesc: t({ id: 'sommelier.secondDesc' }),
												thirdPoints: t({ id: 'sommelier.thirdPoints' }),
												thirdDesc: t({ id: 'sommelier.thirdDesc' }),
												fourthPoints: t({ id: 'sommelier.fourthPoints' }),
												fourthDesc: t({ id: 'sommelier.fourthDesc' }),
												fifthPoints: t({ id: 'sommelier.fifthPoints' }),
												fifthDesc: t({ id: 'sommelier.fifthDesc' }),
											}}
										/>
									)}
								</div>
								{bundleShortDescription && (
									<p className={styles.bundleShortDescription}>
										{bundleShortDescription}
									</p>
								)}
								<div className="shop-controls">
									{!!cheapestAvailableBundle && (
										<DesktopShopControlsSection
											cheapestAvailableBundle={cheapestAvailableBundle}
											isQuantityLoading={isQuantityLoading}
											isIdenticalBundlesDataLoading={
												isIdenticalBundlesDataLoading
											}
											availableQuantity={availableQuantity}
											volumeDiscount={volumeDiscount}
											bundleMeta={bundleMeta}
											bundleLimitPerOrder={bundleLimitPerOrder}
											itemInBasket={itemInBasket}
										/>
									)}
									{basketContext?.minimalPriceForFreeDelivery !== null && (
										<GiftSection bundleId={bundle.id} />
									)}

									<BannerListProduct />

									<div
										className={cx(
											styles.paymentWrapper,
											'bundle-detail-payment'
										)}
									>
										{paymentsData?.length > 0 && (
											<>
												<p className={styles.paymentWrapperHeading}>
													{t({ id: 'productDetail.paymentMethods' })}
												</p>
												<div className={styles.paymentMethods}>
													{paymentsData?.map(
														(
															payment: VinistoOrderDllModelsApiPaymentPayment
														) => (
															<PaymentItem
																key={payment?.id}
																payment={payment}
															/>
														)
													)}
												</div>
											</>
										)}

										{deliveriesData?.length > 0 && (
											<DeliveryMethods
												deliveries={
													deliveriesData as VinistoOrderDllModelsApiDeliveryDelivery[]
												}
												showIcons={false}
											/>
										)}

										<CustomerSupport />
									</div>
									{otherBundles.length > 0 && (
										<OtherSellerOffers otherOffers={otherBundles} />
									)}
									<div className="vinisto-product-detail-beseller-info">
										<SellerInfo />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</ContainerFullWidth>
			{carouselData?.similarBundles?.length !== 0 && (
				<CarouselSection
					data={carouselData?.similarBundles}
					title={`${t({
						id: 'productDetail.carouselHeading.similarProducts',
					})}`}
					isLoading={Boolean(!carouselData?.similarBundles)}
				/>
			)}
			{carouselData?.manufacturerBundles?.length !== 0 && (
				<CarouselSection
					data={carouselData?.manufacturerBundles}
					title={`${t({
						id: 'productDetail.carouselHeading.manufacturerProducts',
					})}`}
					isLoading={Boolean(!carouselData?.manufacturerBundles)}
				/>
			)}
			{lastViewedBundles?.length !== 0 && (
				<CarouselSection
					data={lastViewedBundles}
					title={`${t({
						id: 'productDetail.carouselHeading.currentlyRecommended',
					})}`}
					isLoading={Boolean(!carouselData?.lastViewedBundles)}
				/>
			)}
			{bundlesByTag?.length !== 0 && (
				<CarouselSection
					data={bundlesByTag ?? []}
					title=""
					isLoading={isLoadingBundlesByTag}
				/>
			)}

			{carouselSupplierData.length !== 0 && (
				<CarouselSection
					data={carouselSupplierData ?? []}
					title={`${t({
						id: 'productDetail.carouselHeading.supplierProducts',
					})}`}
					isLoading={Boolean(!carouselSupplierData)}
				/>
			)}
			<div className="container">
				<div className="row">
					<ArticleTabs />
				</div>
			</div>
			<div className={cx('container', styles.quickLinks)}>
				<LinkWidget
					itemClassName={styles.linkWidgets}
					section={
						isMobile || isTablet
							? Allowed_Sections.HOMEPAGE_MOBILE
							: Allowed_Sections.HOMEPAGE_DESKTOP
					}
				/>
			</div>
			<div
				className="vinisto-user-section__menu vinisto-user-section__menu--mobile tablet-mobile-only show position-fixed"
				style={{ bottom: `${footerHeight}px` }}
				ref={sellerSelectionRef}
			>
				<nav className="navbar navbar-dark p-0">
					{(isMobile || isTablet) && (
						<div
							className={cx(styles.sellers, styles.isOnlySeller, {
								reset: !isOnlySupplier,
							})}
						>
							<BundleItem
								key={bundle.id}
								bundle={cheapestAvailableBundle}
								variant={BundleItemVariants.COMPACT}
								isLoading={isQuantityLoading || isIdenticalBundlesDataLoading}
								quantityBox={QuantityBoxVariants.DIRECT}
							/>
						</div>
					)}
				</nav>
			</div>
		</section>
	);
};

export default BundleDetail;
