import { MouseEvent, Suspense, useCallback, useContext } from 'react';
import cx from 'classnames';
import { debounce, get, isNaN, parseInt, uniqueId } from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { MIN_QUANTITY } from 'Components/ProductBox/Components/QuantityBox/constants';
import { CHANGE_DELAY, QUANTITY_TO_REMOVE } from 'Pages/Cart/constants';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import { BasketContext } from 'Services/BasketService';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import Rating from 'Components/Rating';
import Loader from 'Components/View/Loader';
import { VolumeDiscount } from 'vinisto_ui';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import { DirectQuantityBox } from 'Components/QuantityBox';
import CloseIcon from 'Components/Icons/Close';

import { IActiveCartItemProps } from './interfaces';

const ActiveCartItem = ({
	cartItem,
	isLoading = false,
}: IActiveCartItemProps) => {
	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const basketContext = useContext(BasketContext);

	const changeCartItemQuantity = get(
		basketContext,
		'handleOnChangeItemQuantity',
		() => null
	);

	const bundleId = cartItem?.bundleId ?? '';
	const bundleData = cartItem?.bundle;
	const supplierName = bundleData?.supplier?.nameWeb ?? '';

	const availableCount = bundleData?.availableCount ?? 0;
	const minAllowedQuantity = Math.min(availableCount, MIN_QUANTITY);
	const bundleQuantity = cartItem?.quantity ?? minAllowedQuantity;
	const bundleEvaluation = bundleData?.bundleEvaluation ?? {};
	const bundleAvgRating = (bundleEvaluation.averageStars ?? 0) / 2;

	const bundleTotalRatingCount = get(
		bundleEvaluation,
		'totalEvaluationCount',
		0
	);

	const currency = basketContext.basketState?.currency;

	const basePrice = cartItem?.itemPrice;

	const discountedPrice = cartItem?.itemDiscountPrice;

	const {
		volumeDiscountVolume,
		discountedPriceWithoutVat,
		discountedPriceWithVat,
		isDiscounted,
	} = getDiscountPriceValues({
		quantityInBasket: bundleQuantity,
		basePrice,
		discountedPrice,
	});
	const priceWithVat = discountedPriceWithVat ?? basePrice?.valueWithVat;

	const priceWithoutVat = discountedPriceWithoutVat ?? basePrice?.value;

	const getLocalizedValue = useLocalizedValue();

	const totalPriceWithVAT = (priceWithVat ?? 0) * bundleQuantity;

	const totalPriceWithoutVAT = (priceWithoutVat ?? 0) * bundleQuantity;

	const originalTotalPriceWithVAT = isDiscounted
		? (basePrice?.valueWithVat ?? 0) * bundleQuantity
		: null;

	const totalSavings = Number(originalTotalPriceWithVAT) - totalPriceWithVAT;

	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		bundleData?.specificationDetails ?? []
	);

	const changeQuantity = useCallback(
		debounce((value: any) => {
			const newQuantity = parseInt(value);
			if (!isNaN(newQuantity) && newQuantity >= QUANTITY_TO_REMOVE) {
				changeCartItemQuantity(
					Math.max(newQuantity, QUANTITY_TO_REMOVE),
					bundleId
				);
			}
		}, CHANGE_DELAY),
		[bundleId, changeCartItemQuantity]
	);

	const handleOnRemove = useCallback(
		(event?: MouseEvent<HTMLElement>) => {
			event?.preventDefault();
			event?.stopPropagation();
			changeQuantity.cancel();
			changeCartItemQuantity(QUANTITY_TO_REMOVE, bundleId);
		},
		[changeQuantity, bundleId, changeCartItemQuantity]
	);

	const bundle = cartItem?.bundle ?? null;

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item',
				'vinisto-cart__item',
				{ discounted: isDiscounted }
			)}
		>
			<div className="vinisto-user-orders__orders__order-body__item__info">
				{!isLoading && (
					<Link
						className="vinisto-wine__item-overlay"
						to={`/${t({
							id: 'routes.product.route',
						})}/${getLocalizedValue(bundleData?.url ?? [])}`}
					/>
				)}
				<div className="vinisto-user-orders__orders__order-body__item__info__img">
					{isLoading ? (
						<Skeleton
							height="80px"
							width="80%"
						/>
					) : (
						<img
							src={getBundleImage(
								bundleData?.images ?? [],
								IMAGE_SIZE_THUMB_64x80
							)}
							alt={`${t({ id: 'alt.bundleImage' })}`}
						/>
					)}
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__info__data">
					<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
						{isLoading ? (
							<Skeleton />
						) : (
							getLocalizedValue(bundleData?.name ?? [])
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data__score">
						{isLoading ? (
							<Skeleton
								count={5}
								width="15px"
								style={{ margin: '0 .125rem' }}
								inline
							/>
						) : (
							<div className="vinisto-wine__review mb-0">
								<Rating
									defaultValue={bundleAvgRating}
									readOnly
								/>
								<span className="vinisto-wine__review__wrap-count">
									({bundleTotalRatingCount})
								</span>
							</div>
						)}
					</div>
					{isLoading ? (
						<Skeleton width="90%" />
					) : (
						<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
							<BundleProducer
								flag={flag}
								name={producerName}
							/>
						</div>
					)}
				</div>
			</div>

			<div className="vinisto-user-orders__orders__order-body__item__prices">
				{isLoading ? (
					<Skeleton
						count={1.6}
						width="80px"
					/>
				) : (
					<>
						<div className="vinisto-user-orders__orders__order-body__item__prices__total">
							{isDiscounted && (
								<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
									{getLocalizedPrice({
										price: basePrice?.valueWithVat ?? 0,
										// @ts-expect-error possible "undefined" issue
										currency,
									})}
								</span>
							)}
							<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
								{getLocalizedPrice({
									price: priceWithVat ?? 0,
									// @ts-expect-error possible "undefined" issue
									currency,
								})}
							</span>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
							{`${t({ id: 'basket.priceWithoutVAT' })} `}
							<span className="fw-bolder price-span">
								{getLocalizedPrice({
									price: priceWithoutVat ?? 0,
									// @ts-expect-error possible "undefined" issue
									currency,
								})}
							</span>
						</div>
						<div className="vinisto-cart__supplier-name">
							{t(
								{ id: 'bundle.supplierSmall.name' },
								{
									name: (
										<span className="vinisto-color-success fw-bolder">
											{supplierName.length > 0
												? supplierName
												: t({
														id: 'productDetail.seller.name.others',
												  })}
										</span>
									),
								}
							)}
						</div>
					</>
				)}
			</div>
			<div
				className="position-relative"
				style={{ zIndex: 10 }}
			>
				<DirectQuantityBox
					bundle={bundle}
					isLoading={isLoading}
					orderLimitation={bundle?.orderLimitation}
					isBasket={true}
				/>
			</div>
			<div className="vinisto-cart__item__price-remove">
				<div className="vinisto-user-orders__orders__order-body__item__prices">
					{isLoading ? (
						<Skeleton
							count={1.6}
							width="80px"
						/>
					) : (
						<>
							<div className="vinisto-user-orders__orders__order-body__item__prices__total">
								{`${t({ id: 'basket.totalPrice' })} `}
								{isDiscounted && (
									<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
										{getLocalizedPrice({
											price: originalTotalPriceWithVAT ?? 0,
											// @ts-expect-error possible "undefined" issue
											currency,
										})}
									</span>
								)}
								<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
									{getLocalizedPrice({
										price: totalPriceWithVAT,
										// @ts-expect-error possible "undefined" issue
										currency,
									})}
								</span>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
								{`${t({ id: 'basket.priceWithoutVAT' })} `}
								<span className="fw-bolder price-span">
									{getLocalizedPrice({
										price: totalPriceWithoutVAT,
										// @ts-expect-error possible "undefined" issue
										currency,
									})}
								</span>
							</div>
							{Boolean(volumeDiscountVolume) && (
								<VolumeDiscount.Badge>
									{t(
										{ id: 'volumeDiscount.cartItem.firstLine' },
										{
											amount: t(
												{ id: 'amount.pcs' },
												{ count: volumeDiscountVolume }
											),
										}
									)}
									<br />
									{t(
										{ id: 'volumeDiscount.cartItem.secondLine' },
										{
											savings: (
												<VolumeDiscount.Emphasized>
													{getLocalizedPrice({
														price: totalSavings,
														// @ts-expect-error possible "undefined" issue
														currency,
													})}
												</VolumeDiscount.Emphasized>
											),
										}
									)}
								</VolumeDiscount.Badge>
							)}
						</>
					)}
				</div>

				{isLoading ? (
					<Skeleton
						width="25px"
						height="25px"
					/>
				) : (
					<div
						className="vinisto-user-favorite__close"
						onClick={handleOnRemove}
						role="button"
						tabIndex={0}
					>
						<Suspense fallback={<Loader blank />}>
							<CloseIcon
								id={`ico-close-${bundleId ?? uniqueId()}`}
								alt={t({ id: 'alt.delete' })}
								title={``}
								className={`CloseIcon `}
							/>
						</Suspense>
					</div>
				)}
			</div>

			<div className="vinisto-user-favorite__delete-wrap d-none">
				<div className="vinisto-user-favorite__delete-wrap__text">
					{t({ id: 'basket.removed.label' })}
					<br />
					<span className="color-primary pointer fw-bolder">
						{t({ id: 'basket.removed.returnBack' })}
					</span>
				</div>
			</div>
		</div>
	);
};

export default ActiveCartItem;
