import getBundleImage, {
	IMAGE_SIZE_THUMB_368x490,
} from 'Helpers/getBundleImage';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { useMemo } from 'react';

import { Bundle } from '@/domain/bundle';

export const useBundleMeta = (bundle: Bundle | null) => {
	const getLocalizedValue = useLocalizedValue();

	const bundleName = getLocalizedValue(bundle?.name ?? []);
	const bundleShortDescription = getLocalizedValue(
		bundle?.shortDescription ?? []
	);
	const bundleDescription = getLocalizedValue(bundle?.description ?? []);
	const bundleUrl = getLocalizedValue(bundle?.url ?? []);
	const bundleImageSmall = getBundleImage(
		bundle?.images ?? [],
		IMAGE_SIZE_THUMB_368x490
	);

	return useMemo(
		() => ({
			bundleName,
			bundleShortDescription,
			bundleDescription,
			bundleImageSmall,
			bundleUrl,
		}),
		[
			bundleName,
			bundleShortDescription,
			bundleDescription,
			bundleImageSmall,
			bundleUrl,
		]
	);
};

export const getSetBundles = ({ bundle }: { bundle: Bundle }) => {
	const setBundles =
		bundle?.setBundles?.map((setBundle) => {
			const items = bundle.items.filter(
				(item) => item.productId === setBundle.id
			);
			return {
				...setBundle,
				setItems: items.map((item) => ({
					...item,
					...('id' in item && item.id
						? {
								originalPrice:
									bundle.bundlePrices.setItemsPrices?.[item.id].originalPrice,
								setPrice:
									bundle.bundlePrices.setItemsPrices?.[item.id].setPrice,
						  }
						: {}),
				})),
			};
		}) ?? [];

	return setBundles;
};
