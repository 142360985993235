import * as React from 'react';
import { get } from 'lodash-es';
import NewsletterService from 'Services/NewsletterService';
import { PreloaderContext } from 'Components/Preloader/context';
import useAnalytics from 'Hooks/useAnalytics';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { ModalContext } from 'Components/Modal/context';
import {
	FORGOTTEN_PASSWORD_CONFIRM_MODAL,
	REGISTRATION_CONFIRM_MODAL,
} from 'Components/Modal/constants';
import { BasketService } from 'vinisto_api_client';
import { LocalizationContext } from 'Services/LocalizationService';

import { NotificationsContext } from '../NotificationService';
import { StorageContext } from '../StorageService/context';

import { USER_REGISTER_ERROR_USER_EXIST, USER_WRONG_EMAIL } from './constants';
import {
	IAnonymousUID,
	IAuthenticationContextProviderProps,
	IAuthenticationContextValues,
	IVinistoUser,
} from './interfaces';
import { generateUniqueUserHash } from './helpers';

import AuthenticationService from './index';

import { VinistoAuthDllModelsApiUserUserReturn } from '@/api-types/user-api';

const defaultVinistoUser: IVinistoUser = {
	id: null,
	email: null,
	loginKey: null,
	loginHash: '',
	registrationTime: null,
	isAgreementCC: false,
	isEmailVerified: false,
	isNewsletterActive: false,
	nickname: null,
};

const defaultAnonymousUID: IAnonymousUID = {
	anonymousUserId: null,
	initial_timestamp: null,
	expiresOn: null,
};

const defaultAuthenticationContextValues: IAuthenticationContextValues = {
	isLoggedIn: false,
	isLoggining: false,
	vinistoUser: defaultVinistoUser,
	handleOnLogIn: () => null,
	handleOnLogOut: () => null,
	handleOnForceLogOut: () => null,
	saveVinistoUser: () => null,
	handleOnRegister: () => null,
	handleOnForgottenPassword: () => null,
	handleOnConfirmEmail: () => null,
	handleOnResetPassword: () => Promise.resolve(),
	setIsLoggedIn: () => null,
	setVinistoUser: () => null,
	anonymousUID: defaultAnonymousUID,
	handleOnOAuthLogIn: () => null,
};

export const AuthenticationContext = React.createContext(
	defaultAuthenticationContextValues
);

const AuthenticationProvider: React.FC<IAuthenticationContextProviderProps> = (
	props: IAuthenticationContextProviderProps
): JSX.Element => {
	const storageContext = React.useContext(StorageContext);
	const modalContext = React.useContext(ModalContext);
	const preloaderContext = React.useContext(PreloaderContext);
	const notificationsContext = React.useContext(NotificationsContext);
	const authenticationService = React.useMemo(
		() => new AuthenticationService(),
		[]
	);
	const localizationContext = React.useContext(LocalizationContext);

	const getVinistoUserData = React.useCallback(() => {
		const storedVinistoAuth = storageContext.StorageService.getStorageItem(
			'VINISTO_AUTH'
		) as any;
		if (storedVinistoAuth) {
			const storedVinostoUser: IVinistoUser = {
				id: get(storedVinistoAuth, 'id', null),
				email: get(storedVinistoAuth, 'email', null),
				loginHash: get(storedVinistoAuth, 'loginHash', null),
				loginKey: get(storedVinistoAuth, 'loginKey', null),
				registrationTime: get(storedVinistoAuth, 'registrationTime', null),
				isAgreementCC: get(storedVinistoAuth, 'isAgreementCC', false),
				isEmailVerified: get(storedVinistoAuth, 'isEmailVerified', false),
				isNewsletterActive: get(storedVinistoAuth, 'isNewsletterActive', false),
				nickname: get(storedVinistoAuth, 'nickname', null),
			};
			return storedVinostoUser;
		} else {
			return defaultAuthenticationContextValues.vinistoUser;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [vinistoUser, setVinistoUser] = React.useState(getVinistoUserData());
	const [isLoggedIn, setIsLoggedIn] = React.useState(!!vinistoUser.id);

	const getAnonymousUserData = React.useCallback((): IAnonymousUID => {
		if (isLoggedIn) return defaultAnonymousUID;

		const anonymousData = storageContext.StorageService.getStorageItem(
			'ANONYMOUS_UID'
		) as IAnonymousUID | undefined;
		if (
			!anonymousData ||
			!anonymousData?.anonymousUserId ||
			!anonymousData?.initial_timestamp ||
			!anonymousData?.expiresOn
		) {
			const uid = generateUniqueUserHash();
			storageContext.StorageService.setItem('ANONYMOUS_UID', uid);
			return uid;
		}
		const storedAnonymousUser: IAnonymousUID = {
			anonymousUserId: anonymousData?.anonymousUserId,
			initial_timestamp: anonymousData?.initial_timestamp,
			expiresOn: anonymousData?.expiresOn,
		};
		return storedAnonymousUser;
	}, [isLoggedIn]);

	const [anonymousUID, setAnonymousUID] = React.useState<IAnonymousUID>(
		getAnonymousUserData()
	);

	const [isLoggining, setIsLoggining] = React.useState(false);

	const { sendEvent } = useAnalytics();

	const handleOnRegister = React.useCallback(
		(
			email: string,
			password: string,
			isNewsletterActive: boolean,
			isAgreementCC = false
		) => {
			if (!isLoggedIn) {
				authenticationService
					.register(
						email,
						password,
						isNewsletterActive,
						isAgreementCC,
						localizationContext.countryOfSale
					)
					.then((payload) => {
						storageContext.StorageService.removeItem('VINISTO_AUTH');
						storageContext.StorageService.removeItem('VINISTO_ORDER_FORM');
						storageContext.StorageService.removeItem('VINISTO_ORDER_STATE');
						const newVinostoUser: IVinistoUser = {
							id: get(payload, 'user.id', null),
							email: get(payload, 'user.email', null),
							loginKey: get(payload, 'user.loginKey', null),
							loginHash: get(payload, 'user.loginHash', ''),
							registrationTime: get(payload, 'user.registrationTime', null),
							isAgreementCC: get(payload, 'user.isAgreementCC', false),
							isEmailVerified: get(payload, 'user.isEmailVerified', false),
							isNewsletterActive: get(
								payload,
								'user.isNewsletterActive',
								false
							),
							nickname: get(payload, 'user.nickname', null),
						};

						if (
							newVinostoUser.isNewsletterActive &&
							newVinostoUser.email !== null &&
							newVinostoUser.id !== null
						) {
							NewsletterService.subscribe(newVinostoUser.email);
							sendEvent(GA_EVENT.SIGN_UP, {
								method: 'email',
								user_email: newVinostoUser.email,
								user_id: newVinostoUser.id,
							});
						}

						setIsLoggedIn(true);
						setVinistoUser(newVinostoUser);
						storageContext.StorageService.setItem(
							'VINISTO_AUTH',
							newVinostoUser
						);
						modalContext.handleCloseModal();

						setTimeout(() => {
							modalContext.handleOpenModal(REGISTRATION_CONFIRM_MODAL);
						}, 200);
					})
					.catch((error) => {
						if (get(error, 'message') === USER_REGISTER_ERROR_USER_EXIST) {
							notificationsContext.handleShowErrorNotification(
								'notification.message.userExist.error'
							);
						} else {
							notificationsContext.handleShowErrorNotification(
								'notification.message.registration.error'
							);
						}
					});
			}
		},
		[isLoggedIn, modalContext, notificationsContext, storageContext]
	);

	const handleOnLogOut = React.useCallback(() => {
		if (isLoggedIn) {
			preloaderContext.togglePreloader(true);
			authenticationService.logOut(vinistoUser.loginHash).finally(() => {
				setVinistoUser(defaultVinistoUser);
				setIsLoggedIn(false);
				storageContext.StorageService.removeItem('VINISTO_AUTH');
				storageContext.StorageService.removeItem('CART_SHIPPING_DATA');
				notificationsContext.handleShowSuccessNotification(
					'notification.message.logOut.success'
				);
				preloaderContext.togglePreloader();
				const uid = generateUniqueUserHash();
				storageContext.StorageService.setItem('ANONYMOUS_UID', uid);
				setAnonymousUID(uid);
				preloaderContext.togglePreloader(false);
			});
		}
	}, [
		isLoggedIn,
		vinistoUser.loginHash,
		notificationsContext,
		storageContext.StorageService,
	]);

	const saveVinistoUser = React.useCallback((vinistoUser: IVinistoUser) => {
		setVinistoUser(vinistoUser);
		storageContext.StorageService.setItem('VINISTO_AUTH', vinistoUser);
	}, []);

	const handleOnForceLogOut = React.useCallback(() => {
		if (isLoggedIn) {
			const uid = generateUniqueUserHash();
			storageContext.StorageService.setItem('ANONYMOUS_UID', uid);
			setAnonymousUID(uid);
			setVinistoUser(defaultVinistoUser);
			setIsLoggedIn(false);
			storageContext.StorageService.removeItem('VINISTO_AUTH');
			storageContext.StorageService.removeItem('VINISTO_ORDER_FORM');
			storageContext.StorageService.removeItem('VINISTO_ORDER_STATE');
			storageContext.StorageService.removeItem('CART_SHIPPING_DATA');
		}
	}, [isLoggedIn, notificationsContext, storageContext.StorageService]);

	const handleOnOAuthLogIn = React.useCallback(
		async (userdata: VinistoAuthDllModelsApiUserUserReturn) => {
			modalContext.handleCloseModal();
			notificationsContext.handleShowSuccessNotification(
				'notification.message.logIn.success'
			);

			const newVinistoUser: IVinistoUser = {
				id: userdata.user?.id ?? null,
				email: userdata.user?.email ?? null,
				loginHash: userdata.user?.loginHash ?? '',
				loginKey: userdata.user?.loginKey ?? null,
				registrationTime: userdata.user?.registrationTime ?? null,
				isAgreementCC: userdata.user?.isAgreementCC ?? false,
				isEmailVerified: userdata.user?.isEmailVerified ?? false,
				isNewsletterActive: userdata.user?.isNewsletterActive ?? false,
				nickname: userdata.user?.nickname ?? null,
			};

			// merge baskets if user is successful logged in
			if (anonymousUID.anonymousUserId && userdata.user?.loginHash) {
				await BasketService.mergeBaskets({
					anonymousUserId: anonymousUID.anonymousUserId,
					userLoginHash: userdata.user.loginHash,
				});
			}

			storageContext.StorageService.removeItem('ANONYMOUS_UID');
			setAnonymousUID({
				anonymousUserId: null,
				initial_timestamp: null,
				expiresOn: null,
			});

			setIsLoggedIn(true);
			setVinistoUser(newVinistoUser);
			storageContext.StorageService.setItem('VINISTO_AUTH', newVinistoUser);
			setIsLoggining(false);
		},
		[
			modalContext,
			notificationsContext,
			storageContext.StorageService,
			anonymousUID,
		]
	);

	const handleOnLogIn = React.useCallback(
		(email: string, password: string) => {
			setIsLoggining(true);
			if (!isLoggedIn) {
				authenticationService
					.logIn(email, password)
					.then((payload) => {
						modalContext.handleCloseModal();
						notificationsContext.handleShowSuccessNotification(
							'notification.message.logIn.success'
						);
						const newVinostoUser: IVinistoUser = {
							id: get(payload, 'user.id', null),
							email: get(payload, 'user.email', null),
							loginHash: get(payload, 'user.loginHash', ''),
							loginKey: get(payload, 'user.loginKey', null),
							registrationTime: get(payload, 'user.registrationTime', null),
							isAgreementCC: get(payload, 'user.isAgreementCC', false),
							isEmailVerified: get(payload, 'user.isEmailVerified', false),
							isNewsletterActive: get(
								payload,
								'user.isNewsletterActive',
								false
							),
							nickname: get(payload, 'user.nickname', null),
						};

						storageContext.StorageService.removeItem('ANONYMOUS_UID');
						setAnonymousUID({
							anonymousUserId: null,
							initial_timestamp: null,
							expiresOn: null,
						});

						setIsLoggedIn(true);
						setVinistoUser(newVinostoUser);
						storageContext.StorageService.setItem(
							'VINISTO_AUTH',
							newVinostoUser
						);
						setIsLoggining(false);
					})
					.catch(() => {
						notificationsContext.handleShowErrorNotification(
							'notification.message.logIn.error'
						);
						setIsLoggining(false);
					});
			}
		},
		[
			isLoggedIn,
			notificationsContext,
			modalContext,
			storageContext.StorageService,
		]
	);

	const handleOnForgottenPassword = React.useCallback(
		(email: string) => {
			if (!isLoggedIn) {
				authenticationService
					.forgottenPassword(email)
					.then(() => {
						modalContext.handleOpenModal(FORGOTTEN_PASSWORD_CONFIRM_MODAL);
					})
					.catch((error) => {
						if (get(error, 'message') === USER_WRONG_EMAIL) {
							modalContext.handleOpenModal(FORGOTTEN_PASSWORD_CONFIRM_MODAL);
							return;
						}
						notificationsContext.handleShowErrorNotification(
							'notification.message.forgottenPassword.error'
						);
					});
			}
		},
		[isLoggedIn, modalContext]
	);

	const handleOnConfirmEmail = React.useCallback((hash: string) => {
		authenticationService
			.confirmEmail(hash)
			.then(() => {
				notificationsContext.handleShowSuccessNotification(
					'notification.message.confirmEmail.success'
				);
			})
			.catch(() => {
				notificationsContext.handleShowErrorNotification(
					'notification.message.confirmEmail.error'
				);
			});
	}, []);

	const handleOnResetPassword = React.useCallback(
		(resetHash: string, newPassword: string) => {
			if (!isLoggedIn) {
				return authenticationService.resetPassword(resetHash, newPassword);
			}
		},
		[isLoggedIn]
	);

	const localizationContextModel: IAuthenticationContextValues = {
		isLoggedIn,
		isLoggining,
		saveVinistoUser,
		vinistoUser,
		handleOnForceLogOut,
		handleOnRegister,
		handleOnLogOut,
		handleOnLogIn,
		handleOnForgottenPassword,
		handleOnConfirmEmail,
		handleOnResetPassword,
		setIsLoggedIn,
		setVinistoUser,
		anonymousUID,
		handleOnOAuthLogIn,
	};

	return (
		<AuthenticationContext.Provider value={localizationContextModel}>
			{props?.children}
		</AuthenticationContext.Provider>
	);
};

export default AuthenticationProvider;
