import { useContext, useEffect } from 'react';
import { reduce } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { NotificationsContext } from 'Services/NotificationService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { DeviceServiceContext } from 'Services/DeviceService';
import userSectionStyles from 'Pages/UserSection/styles.module.css';
import { CREATED, NOT_FOUND } from 'Pages/UserSection//Orders/constants';
import {
	getLocalizedOrderState,
	getPdfDocument,
	openPdf,
} from 'Pages/UserSection/Orders/helpers';

import BreadCrumbsUserSection from '../Breadcrumbs';

import Order from './Order';

import { VinistoOrderDllModelsApiReturnDataOrderReturn } from '@/api-types/order-api';
import api from '@/api';
import { orderAdapter } from '@/index';
import { Order as OrderType } from '@/domain/order';

interface OrderDetailProps {
	orderId: string;
}

const OrderDetail = ({ orderId }: OrderDetailProps) => {
	const { useFormatMessage } = useContext(LocalizationContext);
	const { vinistoUser } = useContext(AuthenticationContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const { isMobile, isTablet } = useContext(DeviceServiceContext);

	const t = useFormatMessage();

	const userLoginHash = vinistoUser.loginHash ?? '';

	const {
		data: orderData,
		isLoading,
	}: {
		data?: OrderType;
		isLoading: boolean;
	} = useQuery(['order-detail', orderId], () =>
		api
			.get<VinistoOrderDllModelsApiReturnDataOrderReturn>(
				`order-api/orders/${orderId}`,
				{
					UserLoginHash: userLoginHash,
				}
			)
			.then((response) => {
				return response.order ? orderAdapter.fromApi(response.order) : null;
			})
	);

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.user-section.orders.name' })}` }
			)}`,
		});
	}, []);

	const handleOnClickInvoice =
		(orderId: string, documentUrl: string) => async () => {
			const userLoginHash = vinistoUser.loginHash ?? '';
			try {
				const encodedPdfContent = await getPdfDocument(
					orderId,
					documentUrl,
					userLoginHash
				);
				openPdf(encodedPdfContent as string);
			} catch {
				handleShowErrorNotification('userSection.order.invoice.loadError');
			}
		};

	const localizedState = getLocalizedOrderState(orderData?.state ?? '');

	const date = reduce(
		orderData?.stateChangeRecords ?? [],
		(result: number, change: Record<string, any>) =>
			result > NOT_FOUND
				? result
				: change.state === CREATED
				? change.changeTime
				: result,
		NOT_FOUND
	);

	//const localizedCurrency: string = reduce(
	//	currencies,
	//	(result: string, item: Currency) =>
	//		result !== ''
	//			? result
	//			: item.currency === orderData?.orderCurrency
	//			? item.title
	//			: result,
	//	''
	//);

	const orderDataLocalized = {
		...orderData,
		date: date > NOT_FOUND ? date : null,
		// localizedCurrency,
		localizedState,
	};

	return (
		<>
			<BreadCrumbsUserSection
				subpageTitle="routes.user-section.myOrders.name"
				subpageLink={`/${t({ id: 'routes.user-section.route' })}/${t({
					id: 'routes.user-section.orders.route',
				})}`}
			/>

			<h1 className={userSectionStyles.userSectionMainHeader}>
				{t({ id: 'userSection.order.btn.detail' })}{' '}
				{((isMobile || isTablet) && orderData?.orderNumber) ?? ''}
			</h1>

			<div>
				<Order
					isLoading={isLoading}
					// @ts-expect-error TS is complaining about some unions with undefined regarding the underlying BE type. Not sure why.
					order={orderDataLocalized}
					handleOnClickInvoice={handleOnClickInvoice}
				/>
			</div>
		</>
	);
};

export default OrderDetail;
