import { useContext, useEffect } from 'react';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';

import Products from './Products';

import './styles.css';

const Search = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const searchTerm = decodeURIComponent(
		// useParams() won't work here. Unfortunaltely, react-router use its own non-standard way of encoding / decoding route segments.
		// As a result, decodeURIComponent might not work properly (either decode incompletely or outright throw an error).
		window.location.pathname.split('/').pop() ?? ''
	);

	useEffect(() => {
		if (searchTerm) {
			documentHeaderContext.dispatch({
				type: DocumentHeaderAction.setTitle,
				value: `${t({ id: 'app.title.page' }, { title: searchTerm })}`,
			});
		}
	}, [documentHeaderContext, searchTerm, t]);

	return (
		<section id="content-wrapper">
			<Products searchTerm={searchTerm} />
		</section>
	);
};

export default Search;
