import { useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { filter, get, isEmpty, map, orderBy, uniqBy } from 'lodash-es';
import {
	DocumentHeaderAction,
	OpenGraphItemType,
	TwitterCardType,
} from 'Components/DocumentHeader/constants';
import Config from 'Config';
import useIdenticalBundles from 'Hooks/Queries/useIdenticalBundles';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import BundleService from 'Services/Bundle';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { useGetDiscountCoupons } from 'Pages/Bundle/hooks';
import { CategoryData } from 'Services/Bundle/interfaces';
import { TrackEvent } from 'Services/FacebookPixel';
import {
	fetchDeliveriesData,
	fetchPaymentsData,
} from 'Pages/Bundle/Components/BundleDetail/helpers';
import { LANGUAGE, LIMIT } from 'Pages/Bundle/constants';
import { IQueryArgument } from 'Services/ApiService/interfaces';
import useAnalytics from 'Hooks/useAnalytics';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { GaItem } from 'Hooks/useAnalytics/types';
import { CarouselService } from 'vinisto_api_client';
import useUpdateLastViewedRecord from 'Hooks/use-update-last-viewed-record';
import NotFoundPage from 'Pages/NotFound';

import BundleDetail from './Components/BundleDetail';
import BundleDetailSkeleton from './Components/BundleDetailSkeleton';
import { useBundleMeta } from './hooks/use-bundle-detail';

import { VinistoHelperDllEnumsCountryCode } from '@/api-types/product-api';
import { bundleAdapter } from '@/index';
import { Bundle } from '@/domain/bundle';

const BundleProvider = () => {
	const notificationsContext = useContext(NotificationsContext);
	const { fetchQuantity } = useContext(WarehouseContext);
	const { itemUrl: bundleUrl } = useParams();
	const { activeCurrency, countryOfSale } = useContext(LocalizationContext);
	const currency = activeCurrency.currency;

	const {
		data: bundleData,
		refetch: fetchBundleData,
		isLoading: isBundleLoading,
	} = useQuery({
		queryKey: ['bundleDetailData', bundleUrl, { currency, countryOfSale }],
		queryFn: () =>
			BundleService.getBundleByUrl(bundleUrl ?? '', {
				currency,
				countryOfSale: countryOfSale as VinistoHelperDllEnumsCountryCode,
			}).then((response) => bundleAdapter.fromApi(response, { currency })),
		onSuccess: (bundleData) => fetchQuantity(bundleData?.id ?? ''),
		onError: () => {
			notificationsContext.handleShowErrorNotification(
				'productDetail.loading.error'
			);
			return <NotFoundPage />;
		},
	});

	if (isBundleLoading) return <BundleDetailSkeleton />;
	if (!bundleData) return <NotFoundPage />;
	return (
		<BundleContainer
			bundleData={bundleData}
			fetchBundleData={fetchBundleData}
		/>
	);
};

const BundleContainer = ({
	bundleData,
	fetchBundleData,
}: {
	bundleData: Bundle;
	fetchBundleData: () => void;
}) => {
	const warehouseContext = useContext(WarehouseContext);
	const localizationContext = useContext(LocalizationContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const { fetchQuantity } = useContext(WarehouseContext);
	const { sendEvent: sendAnalyticsEvent } = useAnalytics();
	const localize = useLocalizedValue();
	const t = localizationContext.useFormatMessage();
	const currency = localizationContext.activeCurrency.currency;
	const countryOfSale = localizationContext.countryOfSale;

	const bundle = bundleData;

	const bundleMeta = useBundleMeta(bundle);

	const sortedBundleImagesInOriginalFormatAndResolution = useMemo(() => {
		return (
			bundle?.images
				?.sort((a) => (a.isMain ? -1 : 1))
				.map((image) => {
					return {
						src: image?.domainUrls?.original_png ?? '',
					};
				}) ?? []
		);
	}, [bundle]);

	// Just trigger the query as soon as possible, data will be requested in nested components
	void useGetDiscountCoupons({
		bundleId: bundle.id,
		currency,
		countryOfSale: countryOfSale as VinistoHelperDllEnumsCountryCode,
	});

	const { data: categoriesData, isLoading: isCategoryDataLoading } = useQuery({
		queryKey: ['bundleDetailCategoryData', bundle.categoryIds?.[0]],
		queryFn: () => BundleService.getBundleCategories(bundle.id),
		enabled: Boolean(bundle.categoryIds?.[0]),
	});

	const bundleCarouselsData = useQuery(
		['bundleDetailCarouselsData', bundle.id, { currency, countryOfSale }],
		() =>
			CarouselService.getBundleCarousels(bundle.id, {
				Currency: currency,
				CountryOfSale: countryOfSale as VinistoHelperDllEnumsCountryCode,
			}).then((response) => ({
				lastViewedBundles:
					response?.lastViewedBundles?.map((bundle) =>
						bundleAdapter.fromApi(bundle, { currency })
					) ?? [],
				similarBundles:
					response?.similarBundles?.map((bundle) =>
						bundleAdapter.fromApi(bundle, { currency })
					) ?? [],
				manufacturerBundles:
					response?.manufacturerBundles?.map((bundle) =>
						bundleAdapter.fromApi(bundle, { currency })
					) ?? [],
			})),
		{ enabled: Boolean(bundle.id) }
	);

	const supplierBundlesCarousel = useQuery(
		['supplierBundlesCarousel', bundle.id],
		() => {
			return CarouselService.getSupplierBundlesCarousel({
				bundleId: bundle.id,
				currency,
				countryOfSale,
			}).then(
				(bundles) =>
					bundles?.map((bundle) =>
						bundleAdapter.fromApi(bundle, { currency })
					) ?? []
			);
		},
		{ enabled: Boolean(bundle.id) }
	);

	const { data: identicalBundlesData } = useIdenticalBundles(bundle.id, {
		currency,
		countryOfSale: countryOfSale as VinistoHelperDllEnumsCountryCode,
	});

	useEffect(() => {
		const extractIds = (bundles: Bundle[] | undefined) => {
			if (!Array.isArray(bundles)) return [];
			return bundles
				.map((bundle) => bundle.id)
				.filter((id): id is string => Boolean(id));
		};

		if (!bundleCarouselsData.data && !Array.isArray(identicalBundlesData))
			return;

		const allIds = [
			...extractIds(bundleCarouselsData.data?.similarBundles ?? []),
			...extractIds(bundleCarouselsData.data?.manufacturerBundles ?? []),
			...extractIds(bundleCarouselsData.data?.lastViewedBundles ?? []),
			...extractIds(supplierBundlesCarousel.data ?? []),
			...(Array.isArray(identicalBundlesData)
				? identicalBundlesData.map((bundle) => bundle.id)
				: []),
		].filter((id): id is string => Boolean(id));

		if (allIds.length > 0) {
			fetchQuantity(allIds);
		}
	}, [
		bundleCarouselsData.data,
		identicalBundlesData,
		fetchQuantity,
		supplierBundlesCarousel.data,
	]);

	const deliveriesData = useQuery(
		['deliveries'],
		async () => {
			const requestQuery: IQueryArgument[] = [
				{ key: 'Language', value: LANGUAGE },
				{ key: 'Currency', value: currency },
				{ key: 'AllowedCountry', value: countryOfSale },
				{ key: 'Limit', value: LIMIT },
				{ key: 'isForCustomerDelivery', value: true },
				{ key: 'isForStocking', value: false },
				{ key: 'IsCache', value: true },
			];
			return await fetchDeliveriesData(requestQuery);
		},
		{
			cacheTime: 0,
		}
	);

	const deliveries = useMemo(() => {
		const trimmedDeliveries = map(
			// @ts-expect-error fetch fn is written incorrectly, it needs to throw an error not return an empty array!
			deliveriesData.data?.deliveries ?? [],
			(delivery) => ({
				...delivery,
				name: map(delivery.name, (nameItem) => ({
					...nameItem,
					value: nameItem.value.trim(),
				})),
			})
		);

		const activeDeliveries = orderBy(
			uniqBy(filter(trimmedDeliveries, 'isActive'), 'alternativeName[0].value'),
			['order', (delivery) => delivery.deliveryTime ?? 0],
			['asc', 'asc']
		);
		return activeDeliveries;
	}, [deliveriesData]);

	const paymentsData = useQuery(
		['payments'],
		async () => {
			const requestQuery: IQueryArgument[] = [
				{ key: 'Language', value: LANGUAGE },
				{ key: 'Currency', value: currency },
				{ key: 'AllowedCountry', value: countryOfSale },
				{ key: 'IsCache', value: true },
			];
			return await fetchPaymentsData(requestQuery);
		},
		{
			cacheTime: 0,
		}
	);

	const payments = useMemo(() => {
		const activePayments = orderBy(
			uniqBy(
				filter(get(paymentsData, 'data.payments', []), {
					image: {},
					isActive: true,
				}),
				'name[0].value'
			),
			'order',
			'asc'
		);
		return activePayments;
	}, [deliveriesData.isFetched, deliveriesData.data]);

	useEffect(() => {
		if (!bundle || !categoriesData) return;

		const { isDiscounted, basePrice, discountedPrice } = bundle.bundlePrices;

		const { bundleName, bundleDescription, bundleUrl, bundleImageSmall } =
			bundleMeta;

		const setMetaTags = (bundle: Bundle, category?: CategoryData) => {
			const breadcrumbs: Record<string, any>[] = [
				{
					'@type': 'ListItem',
					position: 1,
					name: Config.domainName,
					item: Config.baseUrl,
				},
			];
			if (!isEmpty(category)) {
				breadcrumbs.push({
					'@type': 'ListItem',
					position: 2,
					name: localize(category?.name ?? []),
					item: `${Config.baseUrl}${t({
						id: 'routes.category.route',
					})}/${localize(category?.url ?? []) ?? ''}`,
				});
			}
			breadcrumbs.push({
				'@type': 'ListItem',
				position: breadcrumbs.length + 1,
				name: bundleName,
			});

			dispatch({
				type: DocumentHeaderAction.set,
				value: {
					title: `${t({ id: 'app.title.page' }, { title: bundleName })}`,
					description: bundleDescription,
					twitterCard: {
						card: TwitterCardType.summary,
						title: bundleName,
						description: bundleDescription,
						image: bundleImageSmall,
					},
					openGraph: {
						type: OpenGraphItemType.product,
						title: bundleName,
						url: `${Config.baseUrl}${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`,
						description: bundleDescription,
						image: bundleImageSmall,
						productPriceAmount:
							(isDiscounted
								? basePrice?.valueWithVat
								: discountedPrice?.valueWithVat) ?? 0,
						productPriceCurrency: bundle.bundlePrices.currency,
					},
					jsonLd: [
						{
							'@context': 'https://schema.org',
							'@type': 'BreadcrumbList',
							itemListElement: breadcrumbs,
						},
						{
							'@context': 'https://schema.org/',
							'@type': 'Product',
							name: bundleName,
							image: sortedBundleImagesInOriginalFormatAndResolution[0]?.src,
							description: bundleDescription,
							sku: bundle.id ?? '',
							brand: {
								'@type': 'Brand',
								name: bundleName,
							},
							offers: {
								'@type': 'Offer',
								price:
									(isDiscounted
										? basePrice?.valueWithVat
										: discountedPrice?.valueWithVat) ?? 0,
								priceCurrency: bundle.bundlePrices.currency,
								itemCondition: 'https://schema.org/NewCondition',
							},
						},
					],
				},
			});
		};

		setMetaTags(bundle, categoriesData[0]);
	}, [
		bundle,
		categoriesData,
		dispatch,
		localize,
		sortedBundleImagesInOriginalFormatAndResolution,
		t,
		bundleMeta,
	]);

	useEffect(() => {
		if (!bundle.id || isCategoryDataLoading) return;

		const {
			isDiscounted,
			basePrice,
			discountedPrice,
			discountDifferenceAsAmount,
		} = bundle.bundlePrices;

		const price = basePrice?.value ?? 0;

		TrackEvent('track', 'ViewContent', {
			content_type: 'product',
			content_ids: [bundle?.id],
			content_category: categoriesData && (categoriesData[0]?.name ?? ''),
			value: (isDiscounted ? discountedPrice?.value : basePrice?.value) ?? 0,
			content_name: bundleMeta.bundleName,
			currency,
		});

		const gaItems: GaItem[] = [
			{
				item_category:
					localize(categoriesData && categoriesData[0]?.name) ?? '',
				discount: discountDifferenceAsAmount,
				index: 0,
				item_id: bundle.id,
				item_name: bundleMeta.bundleName,
				price,
				quantity: warehouseContext.getQuantity(bundle?.id ?? ''),
			},
		];

		sendAnalyticsEvent(GA_EVENT.VIEW_ITEM, {
			currency,
			value: (isDiscounted ? discountedPrice?.value : basePrice?.value) ?? 0,
			items: gaItems,
		});
	}, [
		bundle,
		sendAnalyticsEvent,
		categoriesData,
		localize,
		isCategoryDataLoading,
		currency,
		warehouseContext,
		bundleMeta.bundleName,
	]);

	const { hasBeenAlreadyUpdated, updateRecord } = useUpdateLastViewedRecord({
		bundleId: bundle.id,
	});

	useEffect(() => {
		if (!hasBeenAlreadyUpdated) {
			updateRecord(bundle.id);
		}
	}, [bundle.id, hasBeenAlreadyUpdated, updateRecord]);

	return (
		<BundleDetail
			bundle={bundle}
			sortedBundleImagesInOriginalFormatAndResolution={
				sortedBundleImagesInOriginalFormatAndResolution
			}
			bundleMeta={bundleMeta}
			categoriesData={categoriesData}
			carouselData={bundleCarouselsData.data}
			carouselSupplierData={supplierBundlesCarousel?.data ?? []}
			deliveriesData={deliveries}
			paymentsData={payments}
			refreshData={fetchBundleData}
		/>
	);
};

export default BundleProvider;
