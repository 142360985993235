import { AbstractAdapter } from '../abstract-adapter';
import { VinistoBasketDllModelsApiBasketBasket } from '@/api-types/basket-api';
import {
  VinistoHelperDllEnumsCountryCode,
  VinistoHelperDllEnumsCurrency,
} from '@/api-types/product-api';
import { bundleAdapter } from '@/index';
import { type Basket } from './index';

class BasketAdapter extends AbstractAdapter<
  Basket,
  VinistoBasketDllModelsApiBasketBasket
> {
  fromApi(
    apiData: VinistoBasketDllModelsApiBasketBasket,
    request?: {
      currency?:
        | VinistoHelperDllEnumsCurrency
        | keyof typeof VinistoHelperDllEnumsCurrency;
    }
  ): Basket {
    return {
      id: apiData.id,
      userId: apiData.userId ?? '',
      anonymousUserId: apiData.anonymousUserId ?? '',
      items: (apiData.items ?? [])
        .filter((item) => item.bundle?.id)
        .map((apiItem) => ({
          bundleId: apiItem.bundleId ?? '',
          bundle: apiItem.bundle
            ? bundleAdapter.fromApi(
                // @ts-expect-error - enum vs union type, probably swagger-typescript-api issue
                apiItem.bundle,
                { currency: request?.currency }
              )
            : null,
          quantity: apiItem.quantity ?? 0,
          itemPrice: apiItem.itemPrice,
          itemDiscountPrice: apiItem.itemDiscountPrice,
        })),
      currency: apiData.currency
        ? VinistoHelperDllEnumsCurrency[apiData.currency]
        : undefined,
      basketPrice: apiData.basketPrice ?? 0,
      basketPriceWithVat: apiData.basketPriceWithVat ?? 0,
      basketPriceWithVatForDelivery: apiData.basketPriceWithVatForDelivery ?? 0,
      minimalPriceForFreeDelivery: apiData.minimalPriceForFreeDelivery ?? 0,
      discountCoupons: apiData.discountCoupons ?? null,
      basketWeight: apiData.basketWeight ?? 0,
      lastEdit: apiData.lastEdit ?? 0,
      areGiftsRefused: apiData.areGiftsRefused ?? false,
      basketStandardPriceWithVat: apiData.basketStandardPriceWithVat ?? 0,
      basketStandardPrice: apiData.basketStandardPrice ?? 0,
      countryOfSale: apiData.countryOfSale
        ? VinistoHelperDllEnumsCountryCode[apiData.countryOfSale]
        : undefined,
      shippingPackagingBundle: apiData.shippingPackagingBundle ?? null,
    };
  }
}

export default BasketAdapter;
