import cx from 'classnames';
import Carousel from 'Components/Carousel';
import { HOME_PAGE_PRODUCTS_CAROUSEL } from 'Components/Carousel/constants';
import { CARD_TYPE } from 'Components/Carousel/interfaces';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import FreeDeliveryProgressBar from 'Components/FreeDeliveryProgressBar';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import Loader from 'Components/View/Loader';
import { TEST_IDS } from 'Constants/test-ids';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { get, map, size, uniqueId } from 'lodash-es';
import GiftInfo from 'Pages/Cart/Components/GiftInfo';
import GiftItemDesktop from 'Pages/Cart/Components/GiftItemDesktop';
import GiftItemMobile from 'Pages/Cart/Components/GiftItemMobile';
import { sortGiftsByorderPriceLimitFrom } from 'Pages/Cart/helpers';
import {
	CTA_VISIBILITY_THRESHOLD,
	SKELETONS_NUM_ITEMS,
} from 'Pages/CartShippingData/constants';
import {
	lazy,
	MouseEvent,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useInViewport } from 'react-in-viewport';
import Skeleton from 'react-loading-skeleton';
import useAnalytics from 'Hooks/useAnalytics';
import { useNavigate } from 'react-router-dom';
import { VinistoBasketDllModelsApiBasketBasketDiscountCoupon } from 'vinisto_api_client/src/api-types/basket-api';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { IBundleChange } from 'Services/BasketService/interfaces';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { useCurrentlyRecommendedTagBundles } from 'Hooks/useCurrentlyRecommendedTagBundles';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import { GaItem } from 'Hooks/useAnalytics/types';
const CloseIcon = lazy(() => import('Components/Icons/Close'));

import styles from './styles.module.css';
import ActiveCartItem from './Components/ActiveCartItem';
import ActiveCartItemMobile from './Components/ActiveCartItemMobile';
import CartHeader from './Components/CartHeader';
import CouponForm from './Components/CouponForm';
import OutOfStockCartItemList from './Components/OutOfStockCartItemList';
import CouponItem from './Components/CouponItem';
import InActiveCartItem from './Components/InActiveCartItem';
import InActiveCartItemMobile from './Components/InActiveCartItemMobile';
import PackagingOptions from './Components/PackagingOptions';

import '../UserSection/Orders/styles.css';
import './styles.css';

const Cart = () => {
	const basketContext = useContext(BasketContext);
	const {
		basketState,
		bulkUpdate,
		setAreGiftsRefused,
		isLoading: isFetching,
		basketItemsWithTemp: basketItemsWithTempFromContext,
		basketPrice,
		basketPriceWithVat,
		basketStandardPriceWithVat,
		basketItemsOutOfStock,
		setBasketItemsOutOfStock,
		handleRemoveOosItemFromBasket,
	} = useContext(BasketContext);

	const { isDesktop, isTablet, isMobile, footerHeight, headerHeight } =
		useContext(DeviceServiceContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const localizationContext = useContext(LocalizationContext);
	const { activeCurrency, useFormatMessage } = localizationContext;

	const { isLoggedIn, anonymousUID, vinistoUser } = useContext(
		AuthenticationContext
	);
	const { fetchQuantity } = useContext(WarehouseContext);

	const t = useFormatMessage();
	const history = useNavigate();
	const getLocalizedValue = useLocalizedValue();

	// Dont ask me, just refactor it.
	const giftInfoOpen = false;

	// is it necessary to memoize this?
	const basketItems = useMemo(
		() => basketItemsWithTempFromContext?.items ?? [],
		[basketItemsWithTempFromContext]
	);

	const isLoading = isFetching && basketItems?.length === 0;

	const basketItemsWithTemp = basketItems.filter(
		(basketItem) => basketItemsOutOfStock.has(basketItem.bundleId) === false
	);

	const orderableItems = useMemo(() => {
		if (isLoading || !basketItemsWithTemp || basketItemsWithTemp?.length === 0)
			return [];
		return basketItemsWithTemp?.filter((item) => {
			return !basketItemsOutOfStock.has(item.bundleId);
		});
	}, [basketItemsOutOfStock, basketItemsWithTemp, isLoading]);

	const isBasketEmpty =
		!isLoading && (!orderableItems || orderableItems.length === 0);

	const discountCoupons = basketState?.discountCoupons?.discountCoupons;

	const areGiftsRefused = basketState?.areGiftsRefused;

	const handleOnSetAreGiftsRefused = () => {
		const requestParams = isLoggedIn
			? {
					userLoginHash: vinistoUser.loginHash,
					areGiftsRefused: !basketState?.areGiftsRefused,
			  }
			: {
					anonymousUserId: anonymousUID.anonymousUserId ?? '',
					areGiftsRefused: !basketState?.areGiftsRefused,
			  };
		setAreGiftsRefused?.mutate(requestParams);
	};

	const handleGoToShippingPayment = useCallback(
		async (event: MouseEvent<HTMLElement>) => {
			event?.preventDefault();
			event?.stopPropagation();
			if (basketItemsOutOfStock.size > 0) {
				const bundleUpdates: IBundleChange[] = [];
				basketItemsOutOfStock.forEach((cartItem) => {
					const bundleId: string = cartItem.bundleData.bundleId;
					if (bundleId === undefined) return;

					bundleUpdates.push({
						bundleId,
						quantity: cartItem.isTemporaryUnavailable
							? 0
							: cartItem.bundleLimitPerOrder
							? cartItem.bundleLimitPerOrder
							: cartItem.availableWarehouseQty,
					});
				});
				await bulkUpdate(bundleUpdates, false);
			}
			history(`/${t({ id: 'routes.cart.shippingPayment.route' })}`);
		},
		[basketItemsOutOfStock, basketContext, history, t]
	);

	const [isShowFloatingFooter, setIsShowFloatingFooter] = useState(false);
	const ctaRef = useRef<HTMLDivElement>(null);

	const { inViewport: isCtaInViewport } = useInViewport(ctaRef, {
		// consider CTA button hidden behind header and footer
		rootMargin: `${headerHeight * -1}px 0px ${footerHeight * -1}px 0px`,
		threshold: CTA_VISIBILITY_THRESHOLD,
	});

	useEffect(() => {
		if (
			(isMobile || isTablet) &&
			ctaRef.current &&
			get(getComputedStyle(ctaRef.current), 'display') !== 'none'
		) {
			setIsShowFloatingFooter(!isCtaInViewport);
		}
	}, [setIsShowFloatingFooter, isCtaInViewport, isMobile, isTablet]);

	const [isShowFloatingDesktopFooter, setIsShowFloatingDesktopFooter] =
		useState(false);
	const ctaDesktopRef = useRef<HTMLButtonElement>(null);

	const { inViewport: isCtaDesktopInViewport } = useInViewport(ctaDesktopRef, {
		threshold: CTA_VISIBILITY_THRESHOLD,
	});

	useEffect(() => {
		if (
			isDesktop &&
			ctaDesktopRef.current &&
			get(getComputedStyle(ctaDesktopRef.current), 'display') !== 'none'
		) {
			setIsShowFloatingDesktopFooter(!isCtaDesktopInViewport);
		}
	}, [isShowFloatingDesktopFooter, isCtaDesktopInViewport, isDesktop]);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.cart.name' })}` }
			)}`,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { sendEvent } = useAnalytics();

	useEffect(() => {
		const mapBasketItemsToGA = (basketItems: any): GaItem[] =>
			map(basketItems, (item) => {
				return {
					item_id: item?.bundleId,
					item_name: getLocalizedValue(item?.bundle?.name ?? []),
					item_category: item?.bundle?.categories?.[0],
					price: item?.bundle?.prices?.[0]?.value,
					quantity: item?.quantity,
				};
			});

		sendEvent(GA_EVENT.VIEW_CART, {
			currency: activeCurrency.currency,
			value: basketPrice,
			items: mapBasketItemsToGA(basketItems),
		});
	}, [
		basketItems,
		activeCurrency.currency,
		sendEvent,
		getLocalizedValue,
		basketPrice,
	]);

	useEffect(() => {
		if (!Array.isArray(basketItems)) return;
		fetchQuantity(
			basketItems.map((bundle: Record<string, any>) => bundle.bundleId)
		);
	}, [basketItems]);

	const possibleGiftsArraySort = basketContext?.possibleGifts?.sort(
		sortGiftsByorderPriceLimitFrom
	);

	const possibleGiftsArray = basketContext?.possibleGifts?.[0]
		? possibleGiftsArraySort?.[0].bundles?.map((bundle) =>
				getLocalizedValue(bundle.bundle?.name ?? [])
		  )
		: null;

	const giftsLeftToSpent =
		(basketContext?.possibleGifts?.[0] &&
			// @ts-expect-error leftToSpent does not exist on one of the union members
			(basketContext?.possibleGifts?.[0].leftToSpent ?? 0)) ??
		0;
	const possibleGifts = basketContext?.possibleGifts?.[0]
		? possibleGiftsArray?.join(', ')
		: '';

	const hasAnyGift = Boolean(basketContext?.assignedGifts?.[0]);

	const currentlyRecommendedCarouselDataQuery =
		useCurrentlyRecommendedTagBundles();

	const handleGoHome = () => {
		history(`/`);
	};

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<CartHeader />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12">
						{!isLoading && basketItemsOutOfStock.size > 0 && (
							<OutOfStockCartItemList
								{...{
									basketItemsOutOfStock,
									handleRemoveItemFromBasket: handleRemoveOosItemFromBasket,
									setBasketItemsOutOfStock,
								}}
							/>
						)}

						<div>
							<h1 className="vinisto-heading">
								{t({ id: 'basket.inBasket' })}
							</h1>

							{!isMobile && (
								<>
									<div className="vinisto-user-orders__orders__order-body vinisto-cart__items">
										{!isLoading && size(basketItemsWithTemp) === 0 && (
											<strong className={styles.emptyBasketMessage}>
												{t({
													id: 'basket.emptyBasket.message',
												})}
											</strong>
										)}
										{isLoading &&
											Array.from({ length: SKELETONS_NUM_ITEMS }).map(
												(_, key) => (
													<ActiveCartItem
														isLoading={isLoading}
														key={key}
														cartItem={undefined}
													/>
												)
											)}
										{map(basketItemsWithTemp, (cartItem) => {
											const key = `cart-item-${
												cartItem.bundleId ?? uniqueId()
											}`;
											if (cartItem.temp === undefined) {
												return (
													<ActiveCartItem
														key={key}
														cartItem={cartItem}
													/>
												);
											} else if (!isLoading) {
												return (
													<InActiveCartItem
														cartItem={cartItem}
														key={key}
													/>
												);
											}

											return null;
										})}
										{!!discountCoupons?.length &&
											discountCoupons?.map(
												(
													discountCoupon: VinistoBasketDllModelsApiBasketBasketDiscountCoupon,
													index: number
												) => (
													<CouponItem
														key={`${discountCoupon?.id}-${index}`}
														{...discountCoupon}
													/>
												)
											)}
									</div>
									<CouponForm />

									<div className={styles.packagingOptions}>
										<h1 className="vinisto-heading">
											{t({ id: 'basket.packaging.title' })}
										</h1>

										<PackagingOptions />
									</div>

									{(!hasAnyGift && areGiftsRefused) ||
										((basketContext?.possibleGifts?.[0] ||
											basketContext?.assignedGifts?.[0]) && (
											<div className="mt-4 giftInfoBox">
												<div className="giftInfoHeading">
													<h1 className="vinisto-heading">
														{t({ id: 'giftInfo.heading' })}
													</h1>
													{!areGiftsRefused && hasAnyGift && (
														<button
															className="refuseGiftButton"
															onClick={handleOnSetAreGiftsRefused}
														>
															{t({
																id: 'giftInfo.button.refuseGifts',
															})}
															<Suspense fallback={<Loader blank />}>
																<CloseIcon
																	className="CloseIcon"
																	alt="Close"
																/>
															</Suspense>
														</button>
													)}
												</div>
												<div className="vinisto-user-orders__orders__order-body vinisto-cart__items vinisto-cart__items-gifts">
													<button className="showGiftsButton">
														{Boolean(
															(basketContext?.assignedGifts?.[0] ||
																basketContext?.possibleGifts?.[0]) &&
																(((basketContext?.possibleGifts?.[0]
																	// prettier-ignore
																	// @ts-expect-error leftToSpent does not exist on one of the union members
																	?.leftToSpent as number) ??
																	0) > 0 ||
																	basketState?.areGiftsRefused)
														) && (
															<GiftInfo
																areGiftsRefused={Boolean(areGiftsRefused)}
																possibleGifts={possibleGifts ?? ''}
																handleOnAcceptGifts={handleOnSetAreGiftsRefused}
																giftsLeftToSpent={giftsLeftToSpent}
															/>
														)}
													</button>
													{!areGiftsRefused &&
														giftInfoOpen &&
														basketContext?.possibleGifts?.map(
															(possibleGift) => {
																return possibleGift?.bundles?.map(
																	(bundle, key) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle?.name ?? []
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemDesktop
																				key={`possibleGiftItem-${key}`}
																				// @ts-expect-error Slightly uncompatible types; not sure what is going on here
																				orderItemData={giftData}
																				noBorder={key === 0}
																			/>
																		);
																	}
																);
															}
														)}
													{!areGiftsRefused &&
														basketContext?.assignedGifts?.map(
															(assignedGift) => {
																return assignedGift?.bundles?.map(
																	(bundle, key) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle?.name ?? []
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemDesktop
																				key={`assignedGiftItem-${key}`}
																				// @ts-expect-error Slightly uncompatible types; not sure what is going on here
																				orderItemData={giftData}
																			/>
																		);
																	}
																);
															}
														)}
												</div>
											</div>
										))}
								</>
							)}

							{isMobile && (
								<>
									<div className="vinisto-user-orders__orders__order vinisto-user-favorites--mobile vinisto-crosssell--mobile vinisto-cart__items--mobile">
										{!isLoading && size(basketItemsWithTemp) === 0 && (
											<strong className={styles.emptyBasketMessage}>
												{t({
													id: 'basket.emptyBasket.message',
												})}
											</strong>
										)}
										{map(basketItemsWithTemp, (cartItem) => {
											const key = `cart-item-${
												cartItem.bundleId ?? uniqueId()
											}`;
											if (cartItem.temp === undefined) {
												return (
													<ActiveCartItemMobile
														cartItem={cartItem}
														isLoading={isLoading}
														key={key}
													/>
												);
											} else if (!isLoading) {
												return (
													<InActiveCartItemMobile
														key={key}
														cartItem={cartItem}
													/>
												);
											}

											return null;
										})}
										{!!discountCoupons?.length &&
											discountCoupons?.map(
												(
													discountCoupon: VinistoBasketDllModelsApiBasketBasketDiscountCoupon,
													index: number
												) => (
													<CouponItem
														key={`${discountCoupon?.id}-${index}`}
														{...discountCoupon}
													/>
												)
											)}
									</div>
									<CouponForm />

									<div className={cx(styles.packagingOptions, 'mt-3 mt-xl-5')}>
										<h1 className="vinisto-heading">
											{t({ id: 'basket.packaging.title' })}
										</h1>

										<PackagingOptions />
									</div>

									{(!hasAnyGift && areGiftsRefused) ||
										((basketContext?.possibleGifts?.[0] ||
											basketContext?.assignedGifts?.[0]) && (
											<div className="mt-3 mt-xl-5">
												<div className="giftInfoHeading">
													<h1 className="vinisto-heading">
														{t({ id: 'giftInfo.heading' })}
													</h1>
													{!areGiftsRefused && hasAnyGift && (
														<button
															className="refuseGiftButton"
															onClick={handleOnSetAreGiftsRefused}
														>
															{t({
																id: 'giftInfo.button.refuseGifts',
															})}
															<Suspense fallback={<Loader blank />}>
																<CloseIcon
																	className="CloseIcon"
																	alt="Close"
																/>
															</Suspense>
														</button>
													)}
												</div>
												<div className="vinisto-user-orders__orders__order-body vinisto-cart__items">
													<button className="showGiftsButton">
														{Boolean(
															(basketContext?.assignedGifts?.[0] ||
																basketContext?.possibleGifts?.[0]) &&
																(((basketContext?.possibleGifts?.[0]
																	// prettier-ignore
																	// @ts-expect-error leftToSpent does not exist on one of the union members
																	?.leftToSpent as number) ??
																	0) > 0 ||
																	basketState?.areGiftsRefused)
														) && (
															<GiftInfo
																areGiftsRefused={Boolean(areGiftsRefused)}
																possibleGifts={possibleGifts ?? ''}
																handleOnAcceptGifts={handleOnSetAreGiftsRefused}
																giftsLeftToSpent={giftsLeftToSpent}
															/>
														)}
													</button>
													{!areGiftsRefused &&
														giftInfoOpen &&
														basketContext?.possibleGifts?.map(
															(possibleGift) => {
																return possibleGift?.bundles?.map(
																	(bundle, index) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle?.name ?? []
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemMobile
																				key={`possibleGiftItem-${index}`}
																				// @ts-expect-error Slightly uncompatible types; not sure what is going on here
																				orderItemData={giftData}
																				noBorder={index === 0}
																			/>
																		);
																	}
																);
															}
														)}
													{!areGiftsRefused &&
														basketContext?.assignedGifts?.map(
															(assignedGift) => {
																return assignedGift?.bundles?.map(
																	(bundle, index) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle?.name ?? []
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemMobile
																				key={`assignedGiftItem-${index}`}
																				// @ts-expect-error Slightly uncompatible types; not sure what is going on here
																				orderItemData={giftData}
																			/>
																		);
																	}
																);
															}
														)}
												</div>
											</div>
										))}
								</>
							)}
							{
								<div className="vinisto-cart__discount-total">
									{basketContext?.minimalPriceForFreeDelivery !== null && (
										<div className="vinisto-crosssell__ctas__delivery-bar desktop-only">
											<button
												onClick={handleGoHome}
												className="vinisto-cart__ctas__back"
											>
												&lt; {t({ id: 'basket.backToShopping' })}
											</button>
											<FreeDeliveryProgressBar variant="wide-horizontal" />
										</div>
									)}

									{!isMobile && (
										<div className={styles.priceWithButton}>
											<div className="vinisto-user-orders__orders__order-body__item__prices">
												{isLoading ? (
													<Skeleton
														count={1.9}
														width="120px"
													/>
												) : (
													<>
														<div className="vinisto-user-orders__orders__order-body__item__prices__total">
															<span className="vinisto-user-orders__orders__order-body__item__prices__big-total">{`${t(
																{
																	id: 'basket.totalPriceBig',
																}
															)} `}</span>
															<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
																{basketStandardPriceWithVat -
																	basketPriceWithVat >
																	0.001 && (
																	<span className={styles.originalPrice}>
																		{getLocalizedPrice({
																			price: basketStandardPriceWithVat,
																			currency: activeCurrency.currency,
																		}).replace('-0', '0')}
																	</span>
																)}
																{getLocalizedPrice({
																	price: basketPriceWithVat,
																	currency: activeCurrency.currency,
																}).replace('-0', '0')}
															</span>
														</div>
														<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
															{`${t({
																id: 'basket.totalPriceWithoutVAT',
															})} `}
															<span className="fw-normal price-span">
																{getLocalizedPrice({
																	price: basketPrice,
																	currency: activeCurrency.currency,
																})}
															</span>
														</div>
													</>
												)}
											</div>
											{isDesktop && (
												<button
													type="button"
													onClick={handleGoToShippingPayment}
													disabled={isBasketEmpty}
													className="vinisto-btn vinisto-success-btn vinisto-cart__btn-next"
													data-testid={TEST_IDS.BASKET_CONTINUE_BUTTON_DESKTOP}
													ref={ctaDesktopRef}
												>
													{t({ id: 'basket.orderContinue' })}
												</button>
											)}
										</div>
									)}

									{isMobile && (
										<div className="vinisto-cart__price-continue font-family-ubuntu w-100">
											{isLoading ? (
												<Skeleton
													count={1.9}
													width="220px"
												/>
											) : (
												<>
													<div className="d-flex justify-content-between">
														<span className="vinisto-cart__total-label"></span>
														<span className="vinisto-cart__total-price">
															{basketStandardPriceWithVat - basketPriceWithVat >
																0 && (
																<span className={styles.originalPrice}>
																	{getLocalizedPrice({
																		price: basketStandardPriceWithVat,
																		currency: activeCurrency.currency,
																	})}
																</span>
															)}
														</span>
													</div>
													<div className="d-flex justify-content-between">
														<span className="vinisto-cart__total-label">
															{`${t({
																id: 'basket.totalPriceBig',
															})} `}
														</span>
														<span className="vinisto-cart__total-price">
															{getLocalizedPrice({
																price: basketPriceWithVat,
																currency: activeCurrency.currency,
															})}
														</span>
													</div>
													<div className="d-flex justify-content-between mb-1">
														<span className="vinisto-cart__total-label--no-vat">
															{`${t({
																id: 'basket.priceWithoutVAT',
															})} `}
														</span>
														<span className="vinisto-cart__total-label--no-vat">
															{getLocalizedPrice({
																price: basketPrice,
																currency: activeCurrency.currency,
															})}
														</span>
													</div>
												</>
											)}
										</div>
									)}
								</div>
							}
						</div>
					</div>
				</div>
			</div>

			{!isMobile && (
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="position-relative overflow-hidden mb-5">
								<p className="h2 vinisto-heading underline">
									{t({ id: 'basket.buyOthers' })}
								</p>
								<Carousel
									isLoading={currentlyRecommendedCarouselDataQuery.isLoading}
									data={currentlyRecommendedCarouselDataQuery.data}
									carouselType={HOME_PAGE_PRODUCTS_CAROUSEL}
									cardType={CARD_TYPE.CAROUSEL_CLASSIC}
									redirectToCrossSell={false}
									displayPriceAsRange
									quantityBoxType={QuantityBoxTypes.DIRECT}
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			{!isDesktop && (
				<div className="container vinisto-crosssell">
					<div className="row">
						<div className="col-12">
							<div
								className="tablet-mobile-only"
								ref={ctaRef}
							>
								<button
									type="button"
									onClick={handleGoToShippingPayment}
									disabled={isBasketEmpty}
									className="vinisto-btn vinisto-success-btn vinisto-cart-btn-next w-100"
								>
									{t({ id: 'basket.orderContinue' })}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{isShowFloatingFooter && (
				<div
					className="container vinisto-crosssell--floating"
					style={{ bottom: `${footerHeight}px` }}
				>
					<div className="row">
						<div className="col-12">
							<div className="vinisto-cart__price-continue font-family-ubuntu">
								<div>
									{isLoading ? (
										<Skeleton
											count={1.9}
											width="220px"
										/>
									) : (
										<>
											<div className="d-flex justify-content-between">
												<span className="vinisto-cart__total-label"></span>
												<span className="vinisto-cart__total-price">
													{basketStandardPriceWithVat - basketPriceWithVat >
														0 && (
														<span className={styles.originalPrice}>
															{getLocalizedPrice({
																price: basketStandardPriceWithVat,
																currency: activeCurrency.currency,
															})}
														</span>
													)}
												</span>
											</div>
											<div className="d-flex justify-content-between">
												<span className="vinisto-cart__total-label">
													{`${t({
														id: 'basket.totalPriceBig',
													})} `}
												</span>
												<span className="vinisto-cart__total-price">
													{getLocalizedPrice({
														price: basketPriceWithVat,
														currency: activeCurrency.currency,
													})}
												</span>
											</div>
											<div className="d-flex justify-content-between mb-1">
												<span className="vinisto-cart__total-label--no-vat">
													{`${t({
														id: 'basket.priceWithoutVAT',
													})} `}
												</span>
												<span className="vinisto-cart__total-label--no-vat">
													{getLocalizedPrice({
														price: basketPrice,
														currency: activeCurrency.currency,
													})}
												</span>
											</div>
										</>
									)}
								</div>
								<button
									type="button"
									onClick={handleGoToShippingPayment}
									disabled={isBasketEmpty}
									className="vinisto-btn vinisto-success-btn vinisto-cart-btn-next w-100"
								>
									{t({ id: 'basket.orderContinue' })}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{isShowFloatingDesktopFooter && (
				<div className={styles.floatingDesktop}>
					<ContainerFullWidth>
						<div className={styles.floatingDesktopWrap}>
							<div className={styles.floatingPricesButton}>
								<div className={styles.priceWithButton}>
									<div>
										{isLoading ? (
											<Skeleton
												count={1.9}
												width="120px"
											/>
										) : (
											<>
												<div className={styles.floatingPrices}>
													<span className={styles.floatingPriceTotal}>{`${t({
														id: 'basket.totalPriceBig',
													})} `}</span>
													<span className={styles.floatingPriceTotalBig}>
														{basketStandardPriceWithVat - basketPriceWithVat >
															0.001 && (
															<span className={styles.originalPrice}>
																{getLocalizedPrice({
																	price: basketStandardPriceWithVat,
																	currency: activeCurrency.currency,
																}).replace('-0', '0')}
															</span>
														)}
														{getLocalizedPrice({
															price: basketPriceWithVat,
															currency: activeCurrency.currency,
														}).replace('-0', '0')}
													</span>
												</div>
												<div className={styles.floatingPriceTotalNoVat}>
													{`${t({
														id: 'basket.totalPriceWithoutVAT',
													})} `}
													<span className="fw-normal price-span">
														{getLocalizedPrice({
															price: basketPrice,
															currency: activeCurrency.currency,
														})}
													</span>
												</div>
											</>
										)}
									</div>
									{isDesktop && (
										<button
											type="button"
											onClick={handleGoToShippingPayment}
											disabled={isBasketEmpty}
											className="vinisto-btn vinisto-success-btn vinisto-cart__btn-next"
										>
											{t({ id: 'basket.orderContinue' })}
										</button>
									)}
								</div>
							</div>
						</div>
					</ContainerFullWidth>
				</div>
			)}
		</section>
	);
};

export default Cart;
