import api from '@/api';
import { GetLinkMenusResponseContract, MenuLink } from '@/api-types/linkwidgets-api';

const withoutExcluded = (menu: MenuLink[] | null) => {
	if (!menu) return null;

	const filterExcluded = (links: MenuLink[]): MenuLink[] => {
		return links
			.filter(link => link.flags && !link.flags.includes("ExcludedFromMenu"))
			.map(link => ({
				...link,
				childLinks: link.childLinks ? filterExcluded(link.childLinks) : []
			}));
	};

	return filterExcluded(menu);
}

/** Get menus (desktop and mobile) */
const getMenu = async () => {
	// TS problem - env is missing in vinisto-api-client. It is added in projects (eshop, admin etc.)
	const linkWidgetApiKey = import.meta.env.VITE_LINK_WIDGET_API_KEY ?? null;

	let res;
	if (linkWidgetApiKey) {
		// @ts-expect-error Special case for linkwidgets-api
		res = await api.get<GetLinkMenusResponseContract>(
			`linkwidgets-api/link-menus`,
			undefined,
			{
				headers: {
					["X-Api-Key"]: linkWidgetApiKey,
				}
			}
		);
	} else {
		throw new Error('API key is missing');
	}

	if (res.desktopMenu === undefined) {
		throw new Error('No desktopMenu data in response');
	}

	if (res.mobileMenu === undefined) {
		throw new Error('No mobileMenu data in response');
	}

	// Filter excluded
	const out = {
		desktopMenu: withoutExcluded(res.desktopMenu),
		mobileMenu: withoutExcluded(res.mobileMenu),
	};

	return out;
};


const LinkWidgetsService = {
	getMenu,
};

export { LinkWidgetsService };
